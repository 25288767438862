import { PhoneIcon } from "@heroicons/react/24/outline";
import { useCallback, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import Button from "../Button";

interface Props {
    closeKeypad: () => void
}

export const Keypad = (props: Props) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const twilio = useSelector((state: any) => state.twilio);

    const handleNumberClick = useCallback((number: string) => {
        phoneNumber === '' ? setPhoneNumber("+1" + number) : setPhoneNumber(prev => prev + number);
    }, []);

    const handlePhoneChange = (number: string) => {
        setPhoneNumber("+" + number);
    }

    const handleCallClick = () => {
        if (twilio.device) {
            const params = { To: phoneNumber };
            twilio.device.connect(params);
            props.closeKeypad();
        }
    };

    return (
        <div className="fixed top-20 bg-white w-full max-w-[261px] p-6 rounded-md shadow-lg">
            <PhoneInput
                country={'us'}
                disableDropdown
                value={phoneNumber}
                onChange={(phone) => handlePhoneChange(phone as any)}
                inputStyle={{ width: "100%", height: "100%", lineHeight: "1.25", color: "rgb(55 65 81)", border: "1px solid rgb(229 231 235)", marginBottom: "0.75rem" }}
                inputClass={"appearance-none block w-full border border-gray-200 text-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:border-blue"}
            />
            <div className="grid grid-cols-3 gap-4 mt-4">
                {Array.from(Array(9).keys()).map((number) => (
                    <Button
                        key={number}
                        isTerciary
                        iconOnly
                        onClick={() => handleNumberClick(`${number + 1}`)}
                        classNames={"h-12 w-12"}
                    >
                        {number + 1}
                    </Button>
                ))}
                <Button
                    onClick={() => handleNumberClick('*')}
                    isTerciary
                    iconOnly
                    classNames="h-12 w-12"
                >
                    *
                </Button>
                <Button
                    onClick={() => handleNumberClick('0')}
                    isTerciary
                    iconOnly
                    classNames="h-12 w-12"
                >
                    0
                </Button>
                <Button
                    onClick={() => handleNumberClick('#')}
                    isTerciary
                    iconOnly
                    classNames="h-12 w-12"
                >
                    #
                </Button>
            </div>
            <div className="md:w-1/5 md:block hidden">
            </div>
            <div className="w-full place-content-center flex mt-4">
                <Button onClick={handleCallClick} isSecondary iconOnly classNames="h-12 w-12 flex bg-green-600 bg-opacity-100 hover:bg-opacity-80 hover:bg-green-600 rounded-full justify-center items-center">
                    <PhoneIcon className="h-6 w-6  cursor-pointer z-40" />
                </Button>
            </div>
        </div>
    )
}