import moment from 'moment';
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { LoadingMask } from "../../components/LoadingMask";
import { FormSelect } from "../../components/form/FormSelect";
import { fetchNewClientFromLeads, fetchNewLeads, fetchUnContactedAnsweredLeads } from "../../state/dashboard/actions";
import { AppDispatch } from "../../state/store";
import { ReactiveFormInput } from '../../components/form/ReactiveFormInput';
import Button from '../../components/Button';
import { ChartBarIcon, CheckIcon } from '@heroicons/react/24/outline';

export const Dashboard = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const isNewLeadLoading = useSelector((state: any) => state.dashboard.isNewLeadLoading);
    const isNewClientLoading = useSelector((state: any) => state.dashboard.isNewClientLoading);
    const isUnContactedAnsweredLeadLoading = useSelector((state: any) => state.dashboard.isUnContactedAnsweredLeadLoading);
    const isNewLeadLoaded = useSelector((state: any) => state.dashboard.isNewLeadLoaded);
    const isNewClientLoaded = useSelector((state: any) => state.dashboard.isNewClientLoaded);
    const isUnContactedAnsweredLeadLoaded = useSelector((state: any) => state.dashboard.isUnContactedAnsweredLeadLoaded);
    const newLeads = useSelector((state: any) => state.dashboard.newLeads);
    const newClientLeads = useSelector((state: any) => state.dashboard.newClientLeads);
    const unContactedLeads = useSelector((state: any) => state.dashboard.unContactedLeads);
    const unAnsweredLeads = useSelector((state: any) => state.dashboard.unAnsweredLeads);
    const [selectedDateChart, setSelectedDateChat] = useState<string>("7");
    const [selectedOtherDate, setSelectedOtherDate] = useState(false);
    const [loadingNewLeads, setLoadingNewLeads] = useState(false);
    const [loadingNewClients, setLoadingNewClients] = useState(false);
    const [loadingContactedLeads, setLoadingContactedLeads] = useState(false);

    const methods = useForm<any>({});
    const { handleSubmit, control, setValue } = methods;

    useEffect(() => {
        if (currentUser) {
            let startDate = null;
            let endDate = null;

            if(selectedDateChart === "1") {
                setSelectedOtherDate(false);
                setValue("start_date", undefined);
                setValue("end_date", undefined);

                endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
                startDate = moment(endDate).subtract(1, 'days').hours(0).minutes(0).seconds(0).toDate();
            } else if(selectedDateChart === "2") {
                setSelectedOtherDate(false);
                setValue("start_date", undefined);
                setValue("end_date", undefined);
                
                endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
                startDate = moment(endDate).subtract(3, 'days').hours(0).minutes(0).seconds(0).toDate();
            } else if(selectedDateChart === "3") {
                setSelectedOtherDate(false);
                setValue("start_date", undefined);
                setValue("end_date", undefined);
                
                endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
                startDate = moment(endDate).subtract(1, 'weeks').hours(0).minutes(0).seconds(0).toDate();
            } else if(selectedDateChart === "4") {
                setSelectedOtherDate(false);
                setValue("start_date", undefined);
                setValue("end_date", undefined);
                
                endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
                startDate = moment(endDate).subtract(3, 'weeks').hours(0).minutes(0).seconds(0).toDate();
            } else if(selectedDateChart === "5") {
                setSelectedOtherDate(false);
                setValue("start_date", undefined);
                setValue("end_date", undefined);
                
                endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
                startDate = moment(endDate).subtract(1, 'months').hours(0).minutes(0).seconds(0).toDate();
            } else if(selectedDateChart === "6") {
                setSelectedOtherDate(false);
                setValue("start_date", undefined);
                setValue("end_date", undefined);
                
                endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
                startDate = moment(endDate).subtract(3, 'months').hours(0).minutes(0).seconds(0).toDate();
            } else if(selectedDateChart === "7") {
                setSelectedOtherDate(false);
                setValue("start_date", undefined);
                setValue("end_date", undefined);
                
                endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
                startDate = moment(endDate).subtract(6, 'months').hours(0).minutes(0).seconds(0).toDate();
            } else {
                setSelectedOtherDate(true);

                startDate = null;
                endDate = null;
            }

            /*const dayStart = moment(startDate).format('D');
            const restDaysStart = Number(dayStart) - 1;
            startDate = moment(startDate).subtract(restDaysStart, 'days').toDate();*/

            if(startDate !== null && endDate !== null) {
                const end = moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ');
                const start = moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ');

                dispatch(fetchNewLeads({startDate: start, endDate: end}));
                dispatch(fetchNewClientFromLeads({startDate: start, endDate: end}))
                dispatch(fetchUnContactedAnsweredLeads({startDate: start, endDate: end}))
            }
        }
    }, [moment, dispatch, currentUser, setSelectedDateChat, setSelectedOtherDate]);

    const isLoadingCharts = useMemo(() => {
        return ((isNewLeadLoading || isNewClientLoading || isUnContactedAnsweredLeadLoading) && (!isNewLeadLoaded && !isNewClientLoaded && !isUnContactedAnsweredLeadLoaded));
      }, [isNewLeadLoading, isNewClientLoading, isUnContactedAnsweredLeadLoading, isNewLeadLoaded, isNewClientLoaded, isUnContactedAnsweredLeadLoaded])    

    const isRefreshingCharts = useMemo(() => {
        return loadingNewLeads || loadingNewClients || loadingContactedLeads;
    }, [loadingNewLeads, loadingNewClients, loadingContactedLeads, setLoadingNewLeads, setLoadingNewClients, setLoadingContactedLeads]);

    const reloadCharts = async(params: any) => {
        dispatch(fetchNewLeads(params)).then((e) => {
            setLoadingNewLeads(false);
        });

        dispatch(fetchNewClientFromLeads(params)).then((e) => {
            setLoadingNewClients(false);
        });

        dispatch(fetchUnContactedAnsweredLeads(params)).then((e) => {
            setLoadingContactedLeads(false);
        });
    }

    const getDatesOptions = () => {
        return [
            {
                key: 1,
                value: "1",
                label: t("1_DAY")
            },
            {
                key: 2,
                value: "2",
                label: t("3_DAYS")
            },
            {
                key: 3,
                value: "3",
                label: t("1_WEEK")
            },
            {
                key: 4,
                value: "4",
                label: t("3_WEEKS")
            },
            {
                key: 5,
                value: "5",
                label: t("1_MONTH")
            },
            {
                key: 6,
                value: "6",
                label: t("3_MONTHS")
            },
            {
                key: 7,
                value: "7",
                label: t("6_MONTHS")
            },
            {
                key: 8,
                value: "8",
                label: t("OTHER")
            }
        ]
    }

    const handleLastDateChange = (event: any) => {
        setSelectedDateChat(event.target.value);

        let startDate = null;
        let endDate = null;

        if(event.target.value === "1") {
            setSelectedOtherDate(false);
            setValue("start_date", undefined);
            setValue("end_date", undefined);

            setLoadingNewLeads(true);
            setLoadingNewClients(true);
            setLoadingContactedLeads(true);

            endDate = moment('2023-11-23').hours(23).minutes(59).seconds(59).toDate();
            startDate = moment(endDate).subtract(1, 'days').hours(0).minutes(0).seconds(0).toDate();
        } else if(event.target.value === "2") {
            setSelectedOtherDate(false);
            setValue("start_date", undefined);
            setValue("end_date", undefined);

            setLoadingNewLeads(true);
            setLoadingNewClients(true);
            setLoadingContactedLeads(true);
            
            endDate = moment('2023-11-23').hours(23).minutes(59).seconds(59).toDate();
            startDate = moment(endDate).subtract(3, 'days').hours(0).minutes(0).seconds(0).toDate();
        } else if(event.target.value === "3") {
            setSelectedOtherDate(false);
            setValue("start_date", undefined);
            setValue("end_date", undefined);

            setLoadingNewLeads(true);
            setLoadingNewClients(true);
            setLoadingContactedLeads(true);
            
            endDate = moment('2023-11-23').hours(23).minutes(59).seconds(59).toDate();
            startDate = moment(endDate).subtract(1, 'weeks').hours(0).minutes(0).seconds(0).toDate();
        } else if(event.target.value === "4") {
            setSelectedOtherDate(false);
            setValue("start_date", undefined);
            setValue("end_date", undefined);

            setLoadingNewLeads(true);
            setLoadingNewClients(true);
            setLoadingContactedLeads(true);
            
            endDate = moment('2023-11-23').hours(23).minutes(59).seconds(59).toDate();
            startDate = moment(endDate).subtract(3, 'weeks').hours(0).minutes(0).seconds(0).toDate();
        } else if(event.target.value === "5") {
            setSelectedOtherDate(false);
            setValue("start_date", undefined);
            setValue("end_date", undefined);

            setLoadingNewLeads(true);
            setLoadingNewClients(true);
            setLoadingContactedLeads(true);
            
            endDate = moment('2023-11-23').hours(23).minutes(59).seconds(59).toDate();
            startDate = moment(endDate).subtract(1, 'months').hours(0).minutes(0).seconds(0).toDate();
        } else if(event.target.value === "6") {
            setSelectedOtherDate(false);
            setValue("start_date", undefined);
            setValue("end_date", undefined);

            setLoadingNewLeads(true);
            setLoadingNewClients(true);
            setLoadingContactedLeads(true);
            
            endDate = moment('2023-11-23').hours(23).minutes(59).seconds(59).toDate();
            startDate = moment(endDate).subtract(3, 'months').hours(0).minutes(0).seconds(0).toDate();
        } else if(event.target.value === "7") {
            setSelectedOtherDate(false);
            setValue("start_date", undefined);
            setValue("end_date", undefined);

            setLoadingNewLeads(true);
            setLoadingNewClients(true);
            setLoadingContactedLeads(true);
            
            endDate = moment('2023-11-23').hours(23).minutes(59).seconds(59).toDate();
            startDate = moment(endDate).subtract(6, 'months').hours(0).minutes(0).seconds(0).toDate();
        } else {
            setSelectedOtherDate(true);
            startDate = null;
            endDate = null;
        }
        
        /*const dayStart = moment(startDate).format('D');
        const restDaysStart = Number(dayStart) - 1;
        startDate = moment(startDate).subtract(restDaysStart, 'days').toDate();*/

        if(startDate !== null && endDate !== null) {
            const end = moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ');
            const start = moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ');
            
            const params = {startDate: start, endDate: end};
            reloadCharts(params);
        }
    }

    const onSubmitOtherDate = (data: any) => {
        setLoadingNewLeads(true);
        setLoadingNewClients(true);
        setLoadingContactedLeads(true);

        const startDate = moment(data['start_date']).hours(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ssZ');
        const endDate = moment(data['end_date']).hours(23).minutes(59).seconds(59).format('YYYY-MM-DDTHH:mm:ssZ');
        
        const params = {startDate: startDate, endDate: endDate};
        reloadCharts(params);
    }

    return (
        <>
            {isLoadingCharts && <LoadingMask />}
            {isRefreshingCharts && <LoadingMask />}
            <div className="w-full">
                <div className="w-full flex flex-wrap justify-end mt-5">
                    {
                        /*currentUser?.role_id === RoleEnum.ADMIN && 
                        <FormSelect
                            className="md:w-1/4 ml-2"
                            name="agency_data"
                            label={""}
                            options={getDataOptions()}
                            selectedValue={selectedDataChart ?? "1"}
                            handleOnChange={handleLastDataChange}/>*/
                    }
                    <FormSelect
                        className="md:w-1/4 mr-2"
                        name="last_date"
                        label={""}
                        options={getDatesOptions()}
                        selectedValue={selectedDateChart ?? "6"}
                        handleOnChange={handleLastDateChange}
                    />
                </div>

                {
                    selectedOtherDate && <div className="w-full flex flex-wrap ml-2 mr-2">
                        <ReactiveFormInput
                            control={control}
                            type='date'
                            className='md:w-2/5'
                            label={t("START_DATE")}
                            name="start_date"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            type='date'
                            className='md:w-2/5'
                            label={t("END_DATE")}
                            name="end_date"
                            isRequired />
                        <div className='md:w-1/5 flex self-end justify-center mb-3'>
                            <Button onClick={handleSubmit(onSubmitOtherDate)}>
                                <span className='flex items-center font-semibold pr-3'>
                                    <div className='w-8 p-1 aspect-square mr-2'><ChartBarIcon/></div>
                                    {t('GENERATE')}
                                </span>
                            </Button>
                        </div>
                    </div>
                }

                <div className="w-full p-5 grow grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div className="w-full relative overflow-hidden bg-white rounded-lg shadow">
                        <div className="w-full flex flex-col">
                            <div className="text-xl font-bold text-black mt-8 flex justify-center">
                                {t("NEW_LEADS_CHART")}
                            </div>
                            <div className="text-asureis-gray text-base text-justify mt-3 mx-3 flex justify-center">
                                {t("NEW_LEADS_CHART_MESSAGE")}
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}}>
                            <ResponsiveContainer width={"99%"} height={320}>
                                <AreaChart data={newLeads}
                                margin={{
                                    top: 50,
                                    right: 60,
                                    left: 20,
                                    bottom: 50
                                }}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
                                    <Tooltip/>
                                    <YAxis stroke="#577082" allowDecimals={false} interval={'preserveStartEnd'}/>
                                    <XAxis dataKey={"key"} stroke="#577082"/>
                                    <Area type="monotone" dataKey="value" stroke="#003052" fill="#003052" animationDuration={400} />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                    <div className="w-full relative overflow-hidden bg-white rounded-lg shadow">
                        <div className="w-full flex flex-col">
                            <div className="text-xl font-bold text-black mt-8 flex justify-center">
                                {t("UN_CONTACTED_LEADS_CHART")}
                            </div>
                            <div className="text-asureis-gray text-base text-justify mt-3 mx-3 flex justify-center">
                                {t("UN_CONTACTED_LEADS_CHART_MESSAGE")}
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}}>
                            <ResponsiveContainer width={"99%"} height={320}>
                                <AreaChart data={unContactedLeads}
                                margin={{
                                    top: 50,
                                    right: 60,
                                    left: 20,
                                    bottom: 50
                                }}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
                                    <Tooltip/>
                                    <YAxis stroke="#577082" allowDecimals={false} interval={'preserveStartEnd'}/>
                                    <XAxis dataKey={"key"} stroke="#577082"/>
                                    <Area type="monotone" dataKey="value" stroke="#003052" fill="#003052" animationDuration={400} />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                    <div className="w-full relative overflow-hidden bg-white rounded-lg shadow">
                        <div className="w-full flex flex-col">
                            <div className="text-xl font-bold text-black mt-8 flex justify-center">
                                {t("UN_ANSWERED_LEADS_CHART")}
                            </div>
                            <div className="text-asureis-gray text-base text-justify mt-3 mx-3 flex justify-center">
                                {t("UN_ANSWERED_LEADS_CHART_MESSAGE")}
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}}>
                            <ResponsiveContainer width={"99%"} height={320}>
                                <AreaChart data={unAnsweredLeads}
                                margin={{
                                    top: 50,
                                    right: 60,
                                    left: 20,
                                    bottom: 50
                                }}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
                                    <Tooltip/>
                                    <YAxis stroke="#577082" allowDecimals={false} interval={'preserveStartEnd'}/>
                                    <XAxis dataKey={"key"} stroke="#577082"/>
                                    <Area type="monotone" dataKey="value" stroke="#003052" fill="#003052" animationDuration={400} />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                    <div className="w-full relative overflow-hidden bg-white rounded-lg shadow">
                        <div className="w-full flex flex-col">
                            <div className="text-xl font-bold text-black mt-8 flex justify-center">
                                {t("NEW_CLIENT_LEADS_CHART")}
                            </div>
                            <div className="text-asureis-gray text-base text-justify mt-3 mx-3 flex justify-center">
                                {t("NEW_CLIENT_LEADS_CHART_MESSAGE")}
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%"}}>
                            <ResponsiveContainer width={"99%"} height={320}>
                                <AreaChart data={newClientLeads}
                                margin={{
                                    top: 50,
                                    right: 60,
                                    left: 20,
                                    bottom: 50
                                }}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
                                    <Tooltip />
                                    <YAxis stroke="#577082" allowDecimals={false} interval={'preserveStartEnd'}/>
                                    <XAxis dataKey={"key"} stroke="#577082"/>
                                    <Area type="monotone" dataKey="value" stroke="#003052" fill="#003052" animationDuration={400} />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

/**/