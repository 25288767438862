import {
    UserPlusIcon
} from "@heroicons/react/24/outline";
import { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import {
    useNavigate,
} from "react-router-dom";
import Button from "../../components/Button";
import FilteredTable from "../../components/FilteredTable";
import { LoadingMask } from '../../components/LoadingMask';
import { NewUserPath, UpdateUserPath } from '../../constants/Routes';
import { RoleLabel } from "../../enums/Role";

export const Users = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const users = useSelector((state: any) => state.users.users);
    const isLoading = useSelector((state: any) => state.users.isLoading);

    const columns = useMemo(
        () => [
            {
                Header: t('NAME'),
                Cell: ( row: any ) => {
                    const { first_name, last_name } = row.row.original;
                    return `${first_name} ${last_name}`;
                },
            },
            {
                Header: t('PHONE'),
                accessor: "phone",
            },
            {
                Header: t('EMAIL'),
                accessor: "email",
            },
            {
                Header: t('ROLE'),
                accessor: "roleId",
                Cell: ( row: any ) => {
                    const { role_id } = row.row.original;
                    return RoleLabel(`${role_id}`);
                },
            },
        ],
        [t]
    );

    const updateUser = (row: any) => {
        navigate(UpdateUserPath, { state: { user: row } });
    };

    return (<>
        {isLoading && <LoadingMask />}
        <FilteredTable
            columns={columns}
            data={users}
            onRowClick={updateUser}
            buttonAction={<Button onClick={() => navigate(NewUserPath)}>
                <span className='flex items-center font-semibold pr-3'>
                    <div className='w-8 p-1 aspect-square mr-2'><UserPlusIcon /></div>
                    {t('NEW_USER')}
                </span>
            </Button>}
        />
    </>
    )
}