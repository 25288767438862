import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDependentsByClientId } from "./actions";
import { Dependent } from "../../models/Dependant";

interface DependentState {
  dependents: Dependent[],
  isLoading: boolean,
  error: string | null
}

const initialState: DependentState = {
  dependents: [],
  isLoading: false,
  error: null
}

// slice
const dependentsSlice = createSlice({
  name: "dependents",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDependentsByClientId.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchDependentsByClientId.fulfilled, (state, action: PayloadAction<Dependent[]>) => {
        state.isLoading = false;
        state.dependents = action.payload;
      })
      .addCase(fetchDependentsByClientId.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default dependentsSlice.reducer;