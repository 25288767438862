import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AgencyPath, AutomationPath, ClientsPath, CommissionsPath, ConversationsPath, LeadsPath, MetadataPath, PhonePath, PoliciesPath, TagsPath, UsersPath } from "../constants/Routes";
import { NavItemKey } from "../enums/NavItemKey";
import { RoleEnum } from "../enums/Role";
import { NavItem, adminNavItems, defaultNavItems, defaultNavOptions } from "../models/defaultNavItems";
import name from './../assets/images/asureis-side.png';

// add NavItem prop to component prop
type Props = {
  collapsed: boolean;
  navItems?: NavItem[];
  setCollapsed(collapsed: boolean): void;
  shown: boolean;
};
const Sidebar = ({
  collapsed,
  navItems = defaultNavItems,
  shown,
  setCollapsed,
}: Props) => {
  const Icon = collapsed ? ChevronDoubleRightIcon : ChevronDoubleLeftIcon;
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const unreadMessages = useSelector((state: any) => state.twilio.unreadMessages);

  const selectedOption = useMemo(() => {
    if (location.pathname.includes(ClientsPath)) { return NavItemKey.CLIENTS; }
    if (location.pathname.includes(ConversationsPath)) { return NavItemKey.CONVERSATIONS; }
    if (location.pathname.includes(PoliciesPath)) { return NavItemKey.POLICIES; }
    if (location.pathname.includes(PhonePath)) { return NavItemKey.PHONE; }
    if (location.pathname.includes(LeadsPath)) { return NavItemKey.LEADS; }
    if (location.pathname.includes(AutomationPath)) { return NavItemKey.LEAD_AUTOMATIONS; }
    if (location.pathname.includes(UsersPath)) { return NavItemKey.USERS; }
    if (location.pathname.includes(AgencyPath)) { return NavItemKey.AGENCY; }
    if (location.pathname.includes(TagsPath)) { return NavItemKey.TAGS; }
    if (location.pathname.includes(MetadataPath)) { return NavItemKey.METADATA; }
    if (location.pathname.includes(CommissionsPath)) { return NavItemKey.COMMISSIONS; }
    return NavItemKey.DASHBOARD;
  }, [location])

  const selectMenuOption = useCallback((option: NavItem) => {
    if (option.href) { navigate(option.href) }
    if (option.action) { option.action() }
  }, [navigate]);

  return (
    <div
      className={classNames({
        "bg-white text-zinc-50 fixed md:static md:translate-x-0 z-20": true,
        "transition-all duration-300 ease-in-out shadow-lg": true,
        "w-[250px]": !collapsed,
        "w-16": collapsed,
        "-translate-x-full": !shown,
      })}
    >
      <div
        className={classNames({
          "flex flex-col justify-between h-screen sticky inset-0 w-full": true,
        })}
      >
        <div
          className={classNames({
            "flex items-center shadow-sm transition-none":
              true,
            "p-4 justify-between": !collapsed,
            "py-4 justify-center": collapsed,
          })}
        >
          {!collapsed && <span className="whitespace-nowrap"><img className={"w-40"} src={name} alt="Logo" /></span>}
          <button
            className="grid text-asureis-gray place-content-center hover:text-white hover:bg-gold w-10 h-10 rounded-full hidden md:flex items-center"
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon className="w-5 h-5" />
          </button>
        </div>
        <nav className="flex-grow overflow-auto flex flex-col">
          <ul
            className={classNames({
              "my-2 flex flex-col gap-2 items-stretch": true,
            })}
          >
            {navItems.map((item, index) => {
              return (
                <li
                  key={index}
                  className={classNames({
                    "hover:text-black hover:border-gold flex": true, //colors
                    "transition-colors duration-300 cursor-pointer": true, //animation
                    "p-2 mx-3 gap-4 border-l-2": !collapsed,
                    "rounded-full p-2 mx-3 w-10 h-10 border-l-0 hover:border-l-0 hover:text-white hover:bg-gold": collapsed,
                    "text-black border-gold": selectedOption === item.key && !collapsed,
                    "border-l-0 text-white bg-gold": selectedOption === item.key && collapsed,
                    "text-asureis-gray border-transparent": selectedOption !== item.key,
                  })}
                  onClick={() => selectMenuOption(item)}
                >
                  {(item.key === NavItemKey.CONVERSATIONS && unreadMessages.length > 0) ?
                    <>
                      <div className="relative flex justify-center items-center">
                        {item.icon}
                        <div className="bg-red-500 h-2 aspect-square rounded-full absolute top-0 right-0"></div>
                      </div>
                      <span>{!collapsed && item.label}</span></>
                    : <>{item.icon} <span> {!collapsed && item.label}</span></>}
                </li>
              );
            })}
            {currentUser?.role_id !== RoleEnum.AGENT && adminNavItems.map((item, index) => {
              return (
                <li
                  key={index}
                  className={classNames({
                    "hover:text-black hover:border-gold flex": true, //colors
                    "transition-colors duration-300 cursor-pointer": true, //animation
                    "p-2 mx-3 gap-4 border-l-2": !collapsed,
                    "rounded-full p-2 mx-3 w-10 h-10 border-l-0 hover:border-l-0 hover:text-white hover:bg-gold": collapsed,
                    "text-black border-gold": selectedOption === item.key && !collapsed,
                    "border-l-0 text-white bg-gold": selectedOption === item.key && collapsed,
                    "text-asureis-gray border-transparent": selectedOption !== item.key
                  })}
                  onClick={() => selectMenuOption(item)}
                >
                  {item.icon} <span>{!collapsed && item.label}</span>
                </li>
              );
            })}
          </ul>
          <ul className="grow"></ul>
          <ul
            className={classNames({
              "my-2 flex flex-col gap-2 items-stretch bottom-0": true,
            })}
          >
            {defaultNavOptions.map((item, index) => {
              return (
                <li
                  key={index}
                  className={classNames({
                    "hover:text-black hover:border-gold flex": true, //colors
                    "transition-colors duration-300 cursor-pointer": true, //animation
                    "p-2 mx-3 gap-4 border-l-2": !collapsed,
                    "rounded-full p-2 mx-3 w-10 h-10 border-l-0 hover:border-l-0 hover:text-white hover:bg-gold": collapsed,
                    "text-black border-gold": selectedOption === item.key && !collapsed,
                    "border-l-0 text-white bg-gold": selectedOption === item.key && collapsed,
                    "text-asureis-gray border-transparent": selectedOption !== item.key
                  })}
                  onClick={() => selectMenuOption(item)}
                >
                  {item.icon} <span>{!collapsed && item.label}</span>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div >
  );
};
export default Sidebar;