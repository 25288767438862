import classNames from 'classnames'
import React from 'react'
import { useForm, Controller } from 'react-hook-form'

interface Props {
    name: string
    value?: string | number
    label: string
    type?: string
    className?: string
    placeholder?: string
    error?: string
    isDisabled?: boolean
    options: { label: string, value: string }[]
    control: any
    isRequired?: boolean
}

export const ReactiveFormRadioButtonText = (props: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={{ required: props.isRequired ?? false }}
            render={({
                field: { onChange, value },
                fieldState: { error },
            }) => {
                value = props.value
                return <div className={"w-full mb-3 px-3 grid items-end	" + props.className ?? ""}>
                    <label className="block tracking-wide text-gray-700 text-sm mb-2">
                        {props.label}
                    </label><div className='flex gap-4'>{props.options.map((option) => {
                        return <div className="form-check" key={option.value}>
                            <label htmlFor={option.value} className="block tracking-wide text-gray-700 text-xs mb-2">
                                <input
                                    {...register(props.name, { required: props.isRequired })}
                                    type="radio"
                                    name={props.name}
                                    onChange={onChange}
                                    value={option.value}
                                    className={classNames({"form-check-input mr-2": true,
                                    "border-red-500": props.error,
                                    "bg-slate-100": props.isDisabled == true})}
                                    id={option.value}
                                />{option.label}
                            </label>
                        </div>
                    })}
                    </div>
                </div>
            }}
        />
    )
};
