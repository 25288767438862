import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { Lead, NewLead, EditLead } from "../../models/Lead";
import { axiosClient } from "../axiosClient";

export const fetchLeads = createAsyncThunk("leads", async () => {
    const response: AxiosResponse<Lead[]> = await (await axiosClient()).get(`/leads`);
    return response.data
});

export const fetchLeadById = createAsyncThunk("leads/getLeadById", async (id: number) => {
    const response: AxiosResponse<Lead> = await (await axiosClient()).get(`/leads/${id}`);
    return response.data;
});

export const createLead = createAsyncThunk("leads/createLead", async (lead: NewLead, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Lead> = await (await axiosClient()).post(`/leads`, { ...lead });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const createAutomationLead = createAsyncThunk("leads/createAutomationLead", async (params: { lead: NewLead, automationId: number }, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Lead> = await (await axiosClient()).post(`/automations/entry-lead`, { ...params.lead }, { params: { automationId: params.automationId } });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateLead = createAsyncThunk("leads/updateLead", async (params: { lead: EditLead, leadId: number }, { rejectWithValue }) => {
    try {
        await (await axiosClient()).patch(`/leads/${params.leadId}`, { ...params.lead });
        return {
            ...params.lead,
            id: params.leadId
        };
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const uploadLeads = createAsyncThunk("leads/uploadLead", async (leads: any, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Lead[]> = await (await axiosClient()).post(`/leads/upload`, leads);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const startLeadAutomation = createAsyncThunk("leads/startAutomation", async (params: {phone: any, automationId: number }, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<any> = await (await axiosClient()).post(`/automations/start-automation`, undefined, { params: { phone: params.phone, automationId: params.automationId } });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});