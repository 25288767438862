import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json"

export const getCountries = () => {
    countries.registerLocale(enLocale);
    const countryObj = countries.getNames("en", {select: "official"});
    return Object.entries(countryObj).map(([key, value]) => {
        return {
            key: key,
            label: value,
            value: key
        };
    });
};