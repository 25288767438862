import { DocumentArrowDownIcon, DocumentArrowUpIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { PhoneArrowDownLeftIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import * as xlsx from 'xlsx';
import uploadPhoneListTemplate from '../../assets/templates/uploadPhoneListTemplate.xlsx';
import ClientContactForm from "../../components/ClientContactForm";
import FilteredTable from "../../components/FilteredTable";
import { LoadingMask } from "../../components/LoadingMask";
import { Modal } from "../../components/Modal";
import Table from "../../components/Table";
import { ReactiveFormUploadFile } from "../../components/form/ReactiveFormUploadFile";
import { ConversationType } from "../../enums/ConversationType";
import { TwilioDeviceState } from "../../enums/TwilioDeviceState";
import { Client, Client as ClientModel } from "../../models/Client";
import phoneBg from '../../assets/images/phone-bg.png';
import Button from "../../components/Button";
import { PhoneBurnerIcon } from "../../components/Calls/PhoneBurnerIcon";
import { Badge } from "../../components/Calls/Badge";
import { TwilioCall } from "../../models/TwilioCall";
import { messageDateFormat } from "../../utils/functions";
import { ConversationFilter } from "../../models/ConversationFilter";
import { Lead } from "../../models/Lead";
import { CallDirection } from "../../enums/TwilioCalls";
import { ConversationFilters } from "../../components/Calls/ConversationFilters";
import { CallStatusIcon } from "../../components/Calls/CallStatusIcon";
import { CallStatus, CallStatusLabel } from "../../enums/CallStatus";
import LeadContactForm from "../../components/LeadContactForm";

interface Props {
    phone: string,
    isCurrentCall: boolean,
    phoneNames: Map<string, any>,
    endCall: () => void;
    muteCall: () => void;
    unmuteCall: () => void;
    makePhoneCall: (phone: string) => void;
    isMute?: boolean;
}
export const Phone = (props: Props) => {
    const [currentCall, setIsCurrentCall] = useState(false);
    const [isPreparingCall, setIsPreparingCall] = useState(false);
    const [isCallingList, setIsCallingList] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedClients, setSelectedClients] = useState<ClientModel[]>([]);
    const twilio = useSelector((state: any) => state.twilio);
    const deviceState = useSelector((state: any) => state.twilio.twilioDeviceState);
    const [selectedCall, setSelectedCall] = useState<TwilioCall>();
    const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [isUploadingPhones, setIsUploadingPhones] = useState(false);
    const [nameOfCall, setNameOfCall] = useState('');
    const [filter, setFilter] = useState("");
    const [filters, setFilters] = useState<ConversationFilter>({ selectedTypes: [] });
    const [showFilters, setShowFilters] = useState(false);
    const [phonesFromFile, setPhonesFromFile] = useState<{ name: any; phone: string; }[]>();
    const { t } = useTranslation();

    const methods = useForm<{}>();
    const { control } = methods;

    const selectedClient = useMemo(() => {
        return (phoneNumber) ? props.phoneNames.get(phoneNumber) : null;
    }, [props.phoneNames, phoneNumber]);

    const callsWithType = useMemo(() => {
        return twilio.calls.map((call: TwilioCall) => {
            if (call.direction === CallDirection.INBOUND) {
                const client = twilio.phoneContacts.clients.find((x: Client) => x.phone === call.from || x.phone === call.fromFormatted);
                if (client) {
                    return { ...call, conversationType: ConversationType.CLIENTS, name: client.name, id: client.id }
                }
                const lead = twilio.phoneContacts.leads.find((x: Lead) => x.phone === call.from || x.phone === call.fromFormatted);
                if (lead) {
                    return { ...call, conversationType: ConversationType.LEADS, name: lead.name, id: lead.id }
                }
                return { ...call, conversationType: ConversationType.OTHER, name: call.fromFormatted }
            } else {
                const client = twilio.phoneContacts.clients.find((x: Client) => x.phone === call.to || x.phone === call.toFormatted);
                if (client) {
                    return { ...call, conversationType: ConversationType.CLIENTS, name: client.name, id: client.id }
                }
                const lead = twilio.phoneContacts.leads.find((x: Lead) => x.phone === call.to || x.phone === call.toFormatted);
                if (lead) {
                    return { ...call, conversationType: ConversationType.LEADS, name: lead.name, id: lead.id }
                }
                return { ...call, conversationType: ConversationType.OTHER, name: call.toFormatted }
            }

        })
    }, [twilio.calls, twilio.phoneContacts]);

    useEffect(() => {
        if (twilio?.phoneContacts?.clients.find((x: Client) => x.phone === props.phone)) {
            setSelectedCall({conversationType: ConversationType.CLIENTS, from: '', to: '', status: CallStatus.COMPLETED, direction: CallDirection.INBOUND, startTime: ''})
        }
        else if (twilio?.phoneContacts?.leads.find((x: Client) => x.phone === props.phone)) {
            setSelectedCall({conversationType: ConversationType.LEADS, from: '', to: '', status: CallStatus.COMPLETED, direction: CallDirection.INBOUND, startTime: ''})
        }
        setPhoneNumber(props.phone);
        setIsCurrentCall(props.isCurrentCall);
    }, [props.phone, props.isCurrentCall, twilio.phoneContacts]);

    const handleSearchChange = (event: any) => {
        setFilter(event.target.value);
    }

    const filteredCalls = useMemo(() => {
        let calls = callsWithType;
        if (filters.selectedTypes.length > 0) {
            calls = filters.selectedTypes.reduce((acc: string | any[], element: string) => {
                const filteredConversations = callsWithType.filter((x: TwilioCall) => `${x.conversationType}` === element);
                return acc.concat(filteredConversations);
            }, []);
        }
        calls = [...calls]?.sort((a: { dateUpdated: string | number | Date; }, b: { dateUpdated: string | number | Date; }) => new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime());
        if (!filter) {
            // If the filter is an empty string, return all conversations.
            return calls;
        }

        // Convert the filter string to lowercase for case-insensitive comparison.
        const filterLowerCase = filter.toLowerCase();

        return calls.filter((call: any) => {
            const name = call.name.toLowerCase();
            return name.includes(filterLowerCase);
        });
    }, [twilio.conversations, filter, props.phoneNames, filters, callsWithType]);

    const handleCallClick = (call: TwilioCall) => {
        setSelectedCall(call);
        const phone = call.direction === CallDirection.INBOUND
            ? call.from
            : call.to;
        setPhoneNumber(phone);
        if (twilio.device && (twilio.twilioDeviceState === TwilioDeviceState.READY || twilio.twilioDeviceState === TwilioDeviceState.CONNECT)) {
            setIsPreparingCall(true);
            props.makePhoneCall(phone)
        }
    };

    const callSelectedClients = async () => {
        setIsCallingList(true);
        for (let index = 0; index < selectedClients.length; index++) {
            setIsPreparingCall(true);
            const client = selectedClients[index];
            setSelectedClients(prevValues => prevValues.filter(x => x.id !== client.id));
            if (twilio.device) {
                setPhoneNumber(client.phone);
                const params = { To: client.phone };
                const connection = twilio.device.connect(params);

                connection.on('accept', () => {
                    setIsCurrentCall(true);
                    setIsPreparingCall(false);
                });

                // Use Promise to wait for the 'disconnect' event
                await new Promise<void>((resolve) => {
                    connection.on('disconnect', () => {
                        props.endCall();
                        setIsCurrentCall(false);
                        resolve(); // Resolve the Promise when the call is disconnected
                    });
                });
            }
        }
        setIsCallingList(false);
    };

    const openUploadPhoneListModal = () => {
        setIsOpenUploadModal(true);
    };

    const closeUploadPhoneListModal = () => {
        setIsOpenUploadModal(false);
        setSelectedFile(null);
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = link.href = uploadPhoneListTemplate;
        link.download = 'uploadPhoneListTemplate.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const startBatchCalling = () => {
        setIsOpenUploadModal(false);
        callPhonesFromFile();
    }

    const handlePhoneListFile = (e: any) => {
        setSelectedFile(e[0]);
        setIsUploadingPhones(true);
        const reader = new FileReader();
        reader.readAsBinaryString(e[0]);
        reader.onload = (e: any) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "binary", cellDates: true, cellNF: false, cellText: false });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = xlsx.utils.sheet_to_json(sheet);
            const phoneList = parsedData.map((row: any) => {
                const d = {
                    name: row['Name'],
                    phone: `+${row['Phone']}`
                }
                return d;
            });;
            setPhonesFromFile(phoneList);
            setIsUploadingPhones(false);
        }
    }

    const callPhonesFromFile = async () => {
        setIsCallingList(true);
        if (!phonesFromFile) return;
        for (let index = 0; index < phonesFromFile?.length; index++) {
            setIsPreparingCall(true);
            if (twilio.device) {
                setPhoneNumber(phonesFromFile[index].phone);
                setNameOfCall(phonesFromFile[index].name ?? phonesFromFile[index].phone);
                setPhonesFromFile(phonesFromFile.filter(x => x.phone !== phonesFromFile[index].phone))
                const params = { To: phonesFromFile[index].phone };
                const connection = twilio.device.connect(params);

                connection.on('accept', () => {
                    setIsCurrentCall(true);
                    setIsPreparingCall(false);
                });

                // Use Promise to wait for the 'disconnect' event
                await new Promise<void>((resolve) => {
                    connection.on('disconnect', () => {
                        props.endCall();
                        setIsCurrentCall(false);
                        resolve(); // Resolve the Promise when the call is disconnected
                    });
                });
            }
        }
        setNameOfCall('');
        setIsCallingList(false);
        setSelectedFile(null);
    }

    const getUploadedPhoneNumbers = useMemo(() => {
        return phonesFromFile?.map((phone: any) => {
            return {
                columns: [
                    { value: phone.name },
                    { value: phone.phone },
                ]
            }
        }) ?? [];
    }, [phonesFromFile]);

    const showUploadPhoneListModal = () => {
        return <Modal thirdButton={{
            label: t("PHONE_LIST_TEMPLATE"),
            onClick: () => handleDownload(),
            icon: <DocumentArrowDownIcon />
        }} isOpen={isOpenUploadModal} onClose={closeUploadPhoneListModal} label={t('UPLOAD_PHONE_LIST')} saveButton={{ label: t("START_CALLING"), icon: <DocumentArrowUpIcon />, onClick: startBatchCalling }}>
            {isUploadingPhones && <LoadingMask />}
            <div className='w-full flex flex-wrap'>
                {!selectedFile && <ReactiveFormUploadFile
                    control={control}
                    className='md:w-1/1'
                    label={t("LEADS_FILE")}
                    name="leads_file"
                    handleOnChange={handlePhoneListFile} />
                }
                {selectedFile && <div className="w-full px-4 mb-3">
                    <Table columns={[
                        { header: `${t('NAME')}` },
                        { header: `${t('PHONE')}` },
                    ]}
                        data={getUploadedPhoneNumbers} />
                </div>
                }
            </div>
        </Modal>
    }

    const showOrHideFilters = useCallback(() => {
        setShowFilters(prev => !prev);
    }, []);

    const updateFilterValues = (fil: ConversationFilter) => {
        setFilters(fil);
    };

    return (
        <div className="w-full grow p-5 flex flex-col">
            {showUploadPhoneListModal()}
            <div className="text-black max-h-full w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden">
                {twilio.twilioDeviceState === TwilioDeviceState.READY || twilio.twilioDeviceState === TwilioDeviceState.CONNECT ?
                    <div className="w-full grow flex flex-rowoverflow-hidden" style={{ height: 'calc(100vh - 164px)' }}>
                        <div className={classNames({
                            "overflow-x-auto p-5  lg:w-1/3 lg:border-r md:overflow-auto block": true
                        })}>
                            <div className="w-full flex justify-between flex-wrap">
                                <h2 className="text-md font-semibold">Calls</h2>
                                <div className="flex">
                                    <div className="relative flex justify-center lg:items-center gap-2 cursor-pointer">
                                        <Button isDisabled={!twilio.areTwilioCredentialsValid} iconOnly isSecondary onClick={showOrHideFilters}>
                                            <FunnelIcon className="w-6 text-gold" />
                                        </Button>
                                        {showFilters && <ConversationFilters filters={filters} updateFilterValues={updateFilterValues} />}
                                    </div>
                                    <Button isDisabled={!twilio.areTwilioCredentialsValid} iconOnly isSecondary>
                                        <PhoneBurnerIcon />
                                    </Button>
                                </div>
                            </div>
                            <div className="w-full flex flex-wrap pb-2">
                                <input
                                    placeholder="Search ..."
                                    className="bg-white opacity-90 py-2 px-4 rounded shadow w-full"
                                    type="text"
                                    onChange={handleSearchChange}
                                    value={filter}
                                />
                            </div>
                            {filteredCalls?.map((call: TwilioCall) => {
                                return <div className={classNames({
                                    "flex flex-row  gap-3 p-3 hover:bg-neutral-200 cursor-pointer shadow-sm hover:bg-gray": true,
                                })}
                                    onClick={() => handleCallClick(call)}
                                    key={call.sid}>
                                    <div className="flex flex-col grow gap-2">
                                        <div className="flex flex-row justify-between">
                                            <div className="flex gap-2">
                                                <div className={"font-semibold"}>
                                                    {call.name}
                                                </div>
                                                <Badge type={call.conversationType} />
                                            </div>
                                            <div className="text-sm font-light text-neutral-500">{messageDateFormat(call.startTime)}</div>
                                        </div>
                                        <div className="flex gap-2">
                                            <CallStatusIcon type={call.direction} status={call.status} />
                                            <span className="text-sm text-neutral-500">{CallStatusLabel(call.status)}</span>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>

                        {selectedCall?.conversationType === ConversationType.CLIENTS &&
                            <div className="flex flex-col relative w-full lg:w-2/3">
                                <ClientContactForm clientId={selectedClient?.id} />
                            </div>
                        }
                        {selectedCall?.conversationType === ConversationType.LEADS &&
                            <div className="flex flex-col relative w-full lg:w-2/3">
                                <LeadContactForm leadId={selectedClient?.id} />
                            </div>
                        }
                        {selectedCall?.conversationType !== ConversationType.LEADS && selectedCall?.conversationType !== ConversationType.CLIENTS &&
                            <div className="w-full lg:w-2/3 text-center relative mx-auto">
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    <img src={phoneBg} alt="Asureis Logo" className='h-32' />
                                </div>
                            </div>
                        }
                    </div>
                    : <div className="text-l text-center relative inset-y-2/4" style={{ height: 'calc(100vh - 164px)' }}>{t("TWILIO_CONNECTION_ERROR")}</div>}
            </div>
        </div>
    )
}


/*
<div id="chatContainer" className={classNames({
                            "lg:w-1/3 flex flex-col relative w-full items-center p-5": true,
                        })}>
                            <div className="w-full flex flex-wrap">
                                <div className="md:w-1/5">
                                    <button
                                        className="text-asureis-gray hover:text-white place-content-center hover:bg-blue w-10 h-10 rounded-full p-3 opacity-0 md:opacity-100 transition-all duration-300"
                                        onClick={() => setCollapsed(!collapsed)}
                                    >
                                        {!collapsed ? <ChevronDoubleRightIcon className="w-4 h-4" /> : <ChevronDoubleLeftIcon className="w-4 h-4" />}
                                    </button>
                                </div>
                                <div className="md:w-3/5">
                                    {currentCall || isPreparingCall
                                        ? <div className="text-center">
                                            {selectedClient && <div className="text-lg">{selectedClient.name}</div>}
                                            <div className="text-md">{phoneNumber}</div>
                                            <div className="text-sm text-asureis-gray">{t("CALLING")}</div>
                                        </div>
                                        : <PhoneInput
                                            country={'us'}
                                            disableDropdown
                                            value={phoneNumber}
                                            onChange={(phone) => <PhoneInput
                                            country={'us'}
                                            disableDropdown
                                            value={phoneNumber}
                                            onChange={(phone) => handlePhoneChange(phone as any)}
                                            inputStyle={{ width: "100%", height: "100%", lineHeight: "1.25", color: "rgb(55 65 81)", border: "1px solid rgb(229 231 235)", marginBottom: "0.75rem" }}
                                            inputClass={"appearance-none block w-full border border-gray-200 text-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:border-blue"}
                                        />(phone as any)}
                                            inputStyle={{ width: "100%", height: "100%", lineHeight: "1.25", color: "rgb(55 65 81)", border: "1px solid rgb(229 231 235)", marginBottom: "0.75rem" }}
                                            inputClass={"appearance-none block w-full border border-gray-200 text-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:border-blue"}
                                        />
                                    }
                                    <div className="grid grid-cols-3 gap-4 mt-4">
                                        {Array.from(Array(9).keys()).map((number) => (
                                            <Button
                                                key={number}
                                                isTerciary
                                                isDisabled={isPreparingCall}
                                                iconOnly
                                                onClick={() => handleNumberClick(`${number + 1}`)}
                                                classNames={classNames({
                                                    "h-12 w-12 visible": true,
                                                    "hidden": currentCall
                                                })}
                                            >
                                                {number + 1}
                                            </Button>
                                        ))}
                                        {!currentCall && <>
                                            <Button
                                                onClick={() => handleNumberClick('*')}
                                                isTerciary
                                                isDisabled={isPreparingCall}
                                                iconOnly
                                                classNames="h-12 w-12"
                                            >
                                                *
                                            </Button>
                                            <Button
                                                onClick={() => handleNumberClick('0')}
                                                isTerciary
                                                iconOnly
                                                isDisabled={isPreparingCall}
                                                classNames="h-12 w-12"
                                            >
                                                0
                                            </Button>
                                            <Button
                                                onClick={() => handleNumberClick('#')}
                                                isTerciary
                                                iconOnly
                                                isDisabled={isPreparingCall}
                                                classNames="h-12 w-12"
                                            >
                                                #
                                            </Button>
                                        </>}
                                    </div>
                                </div>
                                <div className="md:w-1/5 md:block hidden">
                                </div>
                                <div className="w-full place-content-center flex mt-4">
                                    {currentCall
                                        ? <div className="flex gap-2">
                                            {props.isMute ? <Button onClick={props.unmuteCall} isSecondary iconOnly classNames="justify-center items-center flex bg-blue h-12 w-12 hover:bg-opacity-80">
                                                <div className="relative flex justify-center items-center">
                                                    <MicrophoneIcon className="w-6 h-6 cursor-pointer z-40" />
                                                    <div className="bg-white h-2 aspect-square absolute transform rotate-45 h-6 w-[1px]"></div>
                                                </div>
                                            </Button>
                                                : <Button onClick={props.muteCall} isSecondary iconOnly classNames="flex justify-center items-center h-12 w-12 bg-blue hover:bg-opacity-80"><MicrophoneIcon className="w-6 h-6 cursor-pointer z-40" /></Button>
                                            }
                                            <Button onClick={endCall} isSecondary iconOnly classNames="h-12 w-12 bg-red-600 bg-opacity-100 hover:bg-opacity-80 hover:bg-red-600 rounded-full justify-center items-center flex"><XMarkIcon className="h-6 w-6 cursor-pointer z-40" /></Button>
                                        </div>
                                        : <Button onClick={handleCallClick} isSecondary isDisabled={isPreparingCall} iconOnly classNames="h-12 w-12 flex bg-green-600 bg-opacity-100 hover:bg-opacity-80 hover:bg-green-600 rounded-full justify-center items-center"><PhoneIcon className="h-6 w-6  cursor-pointer z-40" /></Button>}
                                </div>
                            </div>
                        </div>
*/