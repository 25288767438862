import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAddressesByClientId } from "./actions";
import { Address } from "../../models/Address";

interface AddressState {
  addresses: Address[],
  isLoading: boolean,
  error: string | null
}

const initialState: AddressState = {
  addresses: [],
  isLoading: false,
  error: null
}

// slice
const addressSlice = createSlice({
  name: "addresses",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddressesByClientId.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchAddressesByClientId.fulfilled, (state, action: PayloadAction<Address[]>) => {
        state.isLoading = false;
        state.addresses = action.payload;
      })
      .addCase(fetchAddressesByClientId.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default addressSlice.reducer;