import i18next from "i18next";

export enum GenderEnum {
    FEMALE = 1,
    MALE
}

export const GenderLabel = (key: string) => ({ 
    [GenderEnum.FEMALE]: i18next.t('FEMALE'),
    [GenderEnum.MALE]: i18next.t('MALE')
})[key];

export const getGenders = () =>
    Object.values(GenderEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: GenderLabel(value) ?? ""
        }
    });