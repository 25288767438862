import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from '../assets/images/asureis-side.png';
import ProgressBar from "../components/ProgressBar";
import { Form } from '../components/form/Form';
import { SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { ReactiveFormInput } from "../components/form/ReactiveFormInput";
import Button from "../components/Button";
import { ArrowLeftIcon, ArrowRightIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ReactiveFormSelect } from "../components/form/ReactiveFormSelect";
import { GenderLabel, getGenders } from "../enums/Gender";
import PhoneInput from "react-phone-input-2";
import states from "states-us/dist";
import { MaritalStatusEnum, MaritalStatusLabel, getMaritalStatuses } from "../enums/MaritalStatus";
import { ReactiveFormInputArea } from "../components/form/ReactiveFormInputArea";
import { ReactiveFormUploadFile } from "../components/form/ReactiveFormUploadFile";
import { Body, Column, Container, Head, Hr, Html, Img, Preview, Row, Section, Text, render } from "@react-email/components";
import { usFormat } from "../utils/functions";
import { LoadingMask } from "../components/LoadingMask";
import { sendEmail } from "../state/email/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../state/store";
import { ToastContainer, toast } from "react-toastify";
import { ReactiveFormRadioButtonText } from "../components/form/ReactiveFormRadioButtonText";
import { Toast } from "../components/Toast";

export const ApplicationPublicForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [isLoading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [planFiles, setPlanFiles] = useState<any[]>([]);
    const [planFilesBase64, setPlanFilesBase64] = useState<any[]>([]);
    const [pendingDocuments, setPendingDocuments] = useState<any[]>([]);
    const [pendingDocumentsBase64, setPendingDocumentsBase64] = useState<any[]>([]);
    const error = useSelector((state: any) => state.emails.error);
    const [isSendEmailSuccessfully, setIsSendEmailSuccessfully] = useState(false);
    const [isMarried, setIsMarried] = useState(false);
    const MAX_FILE_SIZE = 5242880;
    const MAX_TOTAL_FILES_SIZE = 31457280;

    const [currentStep, setCurrentStep] = useState(1);
    const [steps, setSteps] = useState(6);

    const handleNextStep = () => {
        if (!errorMessage && currentStep < steps) {
            if(currentStep == 3) {
                if(isMarried) {
                    setCurrentStep(currentStep + 1);
                } else {
                    setCurrentStep(currentStep + 2);
                }
            } else {
                setCurrentStep(currentStep + 1);
            }
        }
    };

    const handlePrevStep = () => {
        if (currentStep > 1) {
            if(currentStep == 5) {
                if(isMarried) {
                    setCurrentStep(currentStep - 1);
                } else {
                    setCurrentStep(currentStep - 2);
                }
            } else {
                setCurrentStep(currentStep - 1);
            }
        }
    };

    const getStates = useMemo(() => {
        return states.map(state => {
            return {
                label: state.name,
                value: state.abbreviation,
                key: state.abbreviation
            }
        })
    }, []);

    type ApplicationInput = {
        phone: string,
        spouse: any,
        dependents: any[],
        dependents_claimed: string,
        marital_status: string
    }

    const methods = useForm<ApplicationInput>();
    const { handleSubmit, control, getValues, setValue, watch } = methods;

    const { fields, append, remove } = useFieldArray({
        control,
        name: "dependents" as never
    });

    const dependentsClaimed = useWatch({
        control,
        name: "dependents_claimed", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        defaultValue: "No", // default value before the render
    })

    useEffect(() => {
        if (dependentsClaimed === "Yes") {
            append({})
        }
        else {
            remove();
        }
    }, [dependentsClaimed]);

    const maritalStatus = useWatch({
        control,
        name: "marital_status",
        defaultValue: "0"
    })

    useEffect(() => {
        if (maritalStatus === `${MaritalStatusEnum.MARRIED}`) {
            setIsMarried(true);
        } else {
            setIsMarried(false);
        }
    }, [maritalStatus])

    const onSubmit = (data: any) => {
        if(currentStep != steps) {
            handleNextStep();
        } else {
            sendApplicationEmail(data);
        }
    };

    const sendApplicationEmail = (form: any) => {
        const subject = `${form['first_name']} ${form['last_name']}`;

        let totalFilesSize = 0;
        planFiles.forEach((file) => totalFilesSize += file.size);
        pendingDocuments.forEach((file) => totalFilesSize += file.size);

        if(totalFilesSize > MAX_TOTAL_FILES_SIZE) {
            alert(t("TOTAL_FILES_EXCEED_LIMIT"))
        } else {
            setLoading(true);
            const emailHtml = render(<EmailTemplate form={form} />);

            let attachments= [];
            attachments.push(...planFilesBase64);
            attachments.push(...pendingDocumentsBase64);

            const data = {
                email_html: emailHtml,
                to_address: "aca@fidesins.com",
                attachments: attachments,
                subject: subject
            }

            dispatch(sendEmail({ data: data})).then((e) => {
                if (e.type === "sendEmail/rejected") {
                    Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info);
                    setLoading(false);
                } else {
                    toast(t("SENT_SUCCESFULLY"));
                    setLoading(false);
                    setIsSendEmailSuccessfully(true);
                }
            })
        }
    }

    const handleSuccess = () => {
        //setIsSendEmailSuccessfully(false);
        window.location.reload();
    }

    const handlePlanQuotedFiles = async(e: any) => {
        for(let i = 0; i < e.length; i++) {

            if(e[i].size > MAX_FILE_SIZE) {
                alert(t("FILE_EXCEED_LIMIT"))
            } else {
                setPlanFiles(prev => [...prev, e[i]]);

                const fileBase64 = await convertFileToBase64(e[i]);
                const fileData = {
                    name: e[i].name,
                    path: fileBase64
                };
                setPlanFilesBase64(prev => [...prev, fileData]) 
            }
        }
    }

    const handleRemovePlanQoutedFile = (index: number) => {
        const list = [...planFiles];
        list.splice(index, 1);
        setPlanFiles(list);

        const listBase64 = [...planFilesBase64];
        listBase64.splice(index, 1);
        setPlanFilesBase64(listBase64);
    }

    const handlePendingDocuments = async (e: any) => {
        for(let i = 0; i < e.length; i++) {

            if(e[i].size > MAX_FILE_SIZE) {
                alert(t("FILE_EXCEED_LIMIT"))
            } else {
                setPendingDocuments(prev => [...prev, e[i]]);

                const fileBase64 = await convertFileToBase64(e[i]);
                const fileData = {
                    name: e[i].name,
                    path: fileBase64
                };
                setPendingDocumentsBase64(prev => [...prev, fileData]) 
            }
        }
    }

    const handleRemovePendingDocument = (index: number) => {
        const list = [...pendingDocuments];
        list.splice(index, 1);
        setPendingDocuments(list);

        const listBase64 = [...pendingDocumentsBase64];
        listBase64.splice(index, 1);
        setPendingDocumentsBase64(listBase64);
    }

    const handlePhoneChange = (phone: any) => {
        setValue("phone", phone);
    }

    const convertFileToBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            
            fileReader.onload = () => {
                resolve(fileReader.result)
            };

            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    return (
        <div className='h-screen'>
            {isLoading && <LoadingMask />}
            <ToastContainer progressStyle={{"background": "#D4AF37"}} />
            <div className="absolute top-0 left-0 p-5">
                <img src={logo} alt="" className='h-10 mr-auto' />
            </div>
            <div className='h-full w-full py-20 px-20'>
                <div className="container mx-auto mt-8 p-4" >
                    <ProgressBar currentStep={currentStep} stepsLabels={["", "", "", "", "", ""]} />
                    <div className='grid my-8'>
                        { currentStep === 1 && !isSendEmailSuccessfully &&
                            <Form handleOnSubmit={handleSubmit(onSubmit)}>
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/2'
                                    label={t("AGENT_FIRST_NAME")}
                                    name="agent_first_name"
                                    isRequired />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/2'
                                    label={t("AGENT_LAST_NAME")}
                                    name="agent_last_name"
                                    isRequired />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/1'
                                    label={t("AGENT_EMAIL")}
                                    name="agent_email"
                                    isRequired />
                            </Form>
                        }
                        { currentStep === 2 && !isSendEmailSuccessfully &&
                            <Form handleOnSubmit={handleSubmit(onSubmit)}>
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/2'
                                    label={t("FIRST_NAME")}
                                    name="first_name"
                                    isRequired />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/2'
                                    label={t("LAST_NAME")}
                                    name="last_name"
                                    isRequired />
                                <ReactiveFormInput
                                    control={control}
                                    type='date'
                                    className='md:w-1/2'
                                    label={t("DATE_OF_BIRTH")}
                                    name="date_of_birth"
                                    isRequired />
                                <ReactiveFormSelect
                                    className="md:w-1/2"
                                    name="gender"
                                    label={t("GENDER")}
                                    options={getGenders()}
                                    control={control} />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/1'
                                    label={t("SOCIAL_SECURITY_NUMBER")}
                                    name="social_security_number"
                                    isRequired />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/2'
                                    label={t("EMPLOYER_NAME")}
                                    name="employer_name"
                                    isRequired />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/2'
                                    label={t("YEARLY_INCOME")}
                                    name="yearly_income"
                                    isRequired />
                            </Form>
                        }
                        { currentStep === 3 && !isSendEmailSuccessfully &&
                            <Form handleOnSubmit={handleSubmit(onSubmit)}>
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/1'
                                    label={t("IMMIGRATION_STATUS")}
                                    name="immigration_status"
                                    isRequired />
                                <ReactiveFormRadioButtonText
                                    className={"w-full px-3 md:w-1/1"}
                                    name="tobacco"
                                    label={t("4_OR_MORE_TOBACCO_CLIENT")}
                                    control={control}
                                    options={[{ label: t("YES"), value: "Yes" }, { label: t("NO"), value: "No" }]}
                                />
                                <div className={"w-full px-3 mb-3 md:w-1/2"}>
                                    <label className="block tracking-wide text-gray-700 text-sm mb-2">
                                        {t("PHONE")}
                                    </label>
                                    <PhoneInput
                                        country={'us'}
                                        onChange={phone => handlePhoneChange(phone)}
                                        disableDropdown
                                        inputStyle={{ width: "100%", height: "100%", lineHeight: "1.25", color: "rgb(55 65 81)", border: "1px solid rgb(229 231 235)", marginBottom: "0.75rem" }}
                                        inputClass={"appearance-none block w-full border border-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-blue"}
                                    />
                                </div>
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/2'
                                    label={t("EMAIL")}
                                    name="email"
                                    isRequired />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/2'
                                    label={t("ADDRESS")}
                                    name="address"
                                    isRequired />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/2'
                                    label={t("CITY")}
                                    name="city"
                                    isRequired />
                                <ReactiveFormSelect
                                    className="md:w-1/2"
                                    name="state"
                                    label={t("STATE")}
                                    options={getStates}
                                    control={control} />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/2'
                                    label={t("ZIP_CODE")}
                                    name="zip_code"
                                    isRequired />
                                <ReactiveFormSelect
                                    className="md:w-1/2"
                                    name="marital_status"
                                    label={t("MARITAL_STATUS")}
                                    options={getMaritalStatuses()}
                                    control={control} />
                            </Form>
                        }
                        {
                            currentStep === 4 && !isSendEmailSuccessfully &&
                            <Form handleOnSubmit={handleSubmit(onSubmit)}>
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/3'
                                    label={t("SPOUSE_FIRST_NAME")}
                                    name="spouse.first_name"
                                    isRequired />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/3'
                                    label={t("SPOUSE_LAST_NAME")}
                                    name="spouse.last_name"
                                    isRequired />
                                <ReactiveFormInput
                                    control={control}
                                    type='date'
                                    className='md:w-1/3'
                                    label={t("SPOUSE_DOB")}
                                    name="spouse.dob"
                                    isRequired />
                                <ReactiveFormRadioButtonText
                                    className={"w-full px-3 md:w-1/1"}
                                    name="spouse.tobacco"
                                    label={t("SPOUSE_USED_TOBACCO")}
                                    control={control}
                                    options={[{ label: t("YES"), value: "Yes" }, { label: t("NO"), value: "No" }]}
                                />
                                <ReactiveFormRadioButtonText
                                    className={"w-full px-3 md:w-1/2"}
                                    name={`spouse.immigration_status`}
                                    label={t("SPOUSE_IMMIGRATION_STATUS")}
                                    control={control}
                                    options={[{ label: t("CITIZEN"), value: "Citizen" }, { label: t("PERMANENT_RESIDENT"), value: "Permanent Resident" }, { label: t("WORK_PERMIT"), value: "Work Permit" }, { label: t("PENDING_IMMIGRATION_STATUS"), value: "Pending Immigration Status" }]}
                                />
                                <ReactiveFormRadioButtonText
                                    className={"w-full px-3 md:w-1/2"}
                                    name={`spouse.employment`}
                                    label={t("SPOUSE_EMPLOYMENT")}
                                    control={control}
                                    options={[{ label: t("EMPLOYED"), value: "Employed" }, { label: t("UNEMPLOYED"), value: "Unemployed" }, { label: t("RECEIVING_SOCIAL_SECURITY"), value: "Receiving Social Security" }]}
                                />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/3'
                                    label={t("SPOUSE_YEARLY_INCOME")}
                                    name="spouse.yearly_income"
                                    isRequired />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/3'
                                    label={t("SPOUSE_EMPLOYERS_NAME")}
                                    name="spouse.employer_name"
                                    isRequired />
                                <ReactiveFormInput
                                    control={control}
                                    className='md:w-1/3'
                                    label={t("SPOUSE_SOCIAL_SECURITY_NUMBER")}
                                    name="spouse.ssn"
                                    isRequired />
                                <ReactiveFormRadioButtonText
                                    className={"w-full px-3 md:w-1/1"}
                                    name="spouse.included"
                                    label={t("SPOUSE_INCLUDED_POLICY")}
                                    control={control}
                                    options={[{ label: t("YES"), value: "Yes" }, { label: t("NO"), value: "No" }]}
                                />
                            </Form> 
                        }
                        { currentStep === 5 && !isSendEmailSuccessfully &&
                            <Form handleOnSubmit={handleSubmit(onSubmit)}>
                                <ReactiveFormRadioButtonText
                                    className={"w-full px-3"}
                                    name="dependents_claimed"
                                    label={t("ARE_YOU_CLAIMING_DEPENDENTS")}
                                    control={control}
                                    options={[{ label: t("YES"), value: "Yes" }, { label: t("NO"), value: "No" }]}
                                />
                                {fields?.map((dependent, index) => {
                                    return <Fragment key={dependent.id}>
                                        <h5 className="w-full px-3 py-3">{t("CHILD")} {index + 1} {t("DETAILS")}</h5>
                                        <ReactiveFormInput
                                            control={control}
                                            className='md:w-1/2'
                                            label={t("CHILD_FIRST_NAME")}
                                            name={`dependents.${index}.first_name`}
                                            isRequired />
                                        <ReactiveFormInput
                                            control={control}
                                            className='md:w-1/2'
                                            label={t("CHILD_LAST_NAME")}
                                            name={`dependents.${index}.last_name`}
                                            isRequired />
                                        <ReactiveFormInput
                                            control={control}
                                            type='date'
                                            className='md:w-1/2'
                                            label={t("CHILD_DOB")}
                                            name={`dependents.${index}.date_of_birth`}
                                            isRequired />
                                        <ReactiveFormInput
                                            control={control}
                                            className='md:w-1/2'
                                            label={t("CHILD_SSN")}
                                            name={`dependents.${index}.social_security_number`}
                                            isRequired />
                                        <ReactiveFormRadioButtonText
                                            className={"w-full px-3 md:w-1/2"}
                                            name={`dependents.${index}.immigration_status`}
                                            label={t("CHILD_IMMIGRATION_STATUS")}
                                            control={control}
                                            options={[{ label: t("CITIZEN"), value: "Citizen" }, { label: t("PERMANENT_RESIDENT"), value: "Permanent Resident" }, { label: t("WORK_PERMIT"), value: "Work Permit" }, { label: t("PENDING_IMMIGRATION_STATUS"), value: "Pending Immigration Status" }]}
                                        />
                                        <div className='flex md:w-1/2'>
                                            <ReactiveFormRadioButtonText
                                                className={"w-full px-3 md:w-1/1"}
                                                name={`dependents.${index}.included_policy`}
                                                label={t("CHILD_INCLUDED_POLICY")}
                                                control={control}
                                                options={[{ label: t("YES"), value: "Yes" }, { label: t("NO"), value: "No" }]}
                                            />
                                            <div className='self-end mb-3'>
                                                {+index === +getValues("dependents").length - 1
                                                    ? <Button iconOnly classNames='h-10 w-10' onClick={() => append({
                                                        name: "",
                                                        last_name: "",
                                                        social_security_number: ""
                                                    })}><PlusIcon className="h-4 w-4 ml-1" /></Button>
                                                    : <Button iconOnly classNames='h-10 w-10' onClick={() => remove(index)}><TrashIcon className="h-4 w-4 ml-1" /></Button>
                                                }
                                            </div>
                                        </div>
                                    </Fragment>
                                })}
                            </Form>
                        }
                        { currentStep === steps && !isSendEmailSuccessfully &&
                            <Form handleOnSubmit={handleSubmit(onSubmit)}>
                                <ReactiveFormInputArea
                                    control={control}
                                    className='md:w-1/1'
                                    label={t("BANK_CREDIT_CARD_INFORMATION")}
                                    name="bank_credit_information"
                                    isRequired />
                                <ReactiveFormUploadFile 
                                    control={control}
                                    className='md:w-1/1'
                                    label={t("PLAN_QUOTED")}
                                    name="plan_qouted"
                                    handleOnChange={handlePlanQuotedFiles}/>
                                <label className="text-sm text-gray-500 ml-3 mb-3">{t("MAX_FILE_SIZE")}</label>
                                {
                                    planFiles.map((file, index) => {
                                        return (
                                            <Fragment key={file.name}>
                                                <div className="w-full px-4 mb-3">
                                                    <div className="flex justify-between">
                                                        <label className="text-gray-600 text-sm self-center">{file.name}</label>
                                                        <Button iconOnly classNames='h-7 w-7 self-end' onClick={() => handleRemovePlanQoutedFile(index)}><TrashIcon className="h-3 w-3" /></Button>
                                                    </div>
                                                    <hr className="mt-3"></hr>
                                                </div>
                                            </Fragment>
                                        )
                                    })
                                }
                                <ReactiveFormRadioButtonText
                                    className={"w-full px-3"}
                                    name="upload_bulk_one"
                                    label={t("UPLOAD_BULK_ONE")}
                                    control={control}
                                    options={[{ label: t("BULK"), value: "Bulk" }, { label: t("ONE_BY_ONE"), value: "One by one" }]}
                                    />
                                <ReactiveFormUploadFile 
                                    control={control}
                                    className='md:w-1/1'
                                    label={t("UPLOAD_PENDING_DOCUMENTS")}
                                    name="pending_documents"
                                    handleOnChange={handlePendingDocuments}/>
                                <label className="text-sm text-gray-500 ml-3 mb-3">{t("MAX_FILE_SIZE")}</label>
                                {
                                    pendingDocuments.map((file, index) => {
                                        return (
                                            <Fragment key={file.name}>
                                                <div className="w-full px-4 mb-3">
                                                    <div className="flex justify-between">
                                                        <label className="text-gray-600 text-sm self-center">{file.name}</label>
                                                        <Button iconOnly classNames='h-7 w-7 self-end' onClick={() => handleRemovePendingDocument(index)}><TrashIcon className="h-3 w-3" /></Button>
                                                    </div>
                                                    <hr className="mt-3"></hr>
                                                </div>
                                            </Fragment>
                                        )
                                    })
                                }
                            </Form>
                        }
                        { isSendEmailSuccessfully &&
                            <div className="grid justify-items-center">
                                <h4 className="text-xl font-semibold mt-8 mb-6">{t("APPLICATION_SENT_SUCCESSFULLY")}</h4>
                                <label className="my-4">{t("APPLICATION_MESSAGE")}</label>
                            </div>
                        }
                    </div>
                    { !isSendEmailSuccessfully ?
                        <div className="flex justify-between">
                            <Button
                                onClick={handlePrevStep}
                                isDisabled={currentStep === 1}
                            >
                                <span className='flex items-center font-semibold pr-3'>
                                    <div className='w-8 p-1 aspect-square mr-2'><ArrowLeftIcon /></div>
                                    {t('PREVIOUS')}
                                </span>
                            </Button>
                            {currentStep === steps ?
                                <Button isTypeSubmit={true} onClick={handleSubmit(onSubmit)}>
                                    <span className='flex items-center font-semibold pl-3 pr-3'>
                                        {t('SUBMIT')}
                                    </span>
                                </Button>
                                :
                                <Button isTypeSubmit={true} onClick={handleSubmit(onSubmit)}>
                                    <span className='flex items-center font-semibold pl-3'>
                                        {t('NEXT')}
                                        <div className='w-8 p-1 aspect-square ml-2'><ArrowRightIcon /></div>
                                    </span>
                                </Button>
                            }
                        </div>
                        :
                        <div className="flex justify-center my-3">
                            <Button isTypeSubmit={false} onClick={handleSuccess} classNames="py-3">
                                <span className='flex items-center font-semibold pl-4 pr-4'>
                                    {t('ACCEPT')}
                                </span>
                            </Button>
                        </div>
                }
                </div>
            </div>
        </div>
    );
}

interface Props {
    form: any | null | undefined;
}

export const EmailTemplate = (form: Props) => {
    const { t } = useTranslation();
    const children = form.form["dependents"];

    const main = {
        backgroundColor: '#ffffff',
        fontFamily:
            '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
    };

    const container = {
        margin: '0 auto',
        padding: '20px 0 48px',
    };

    const logoC = {
        margin: '0 auto',
    };

    const rowFirst = {
        margin: '20px 0 0 0'
    }

    const row = {
        margin: '10px 0 0 0'
    }

    const rowChild = {
        margin: '0px 0 0 0'
    }

    const title = {
        margin: '16px 5px 0 5px',
        fontSize: '15px',
        fontWeight: 'bold'
    };

    const titleAp = {
        margin: '30px 5px 0 5px',
        fontSize: '20px',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center'
    };

    const paragraph = {
        margin: '0 5px 0 5px',
        fontSize: '15px',
        lineHeight: '26px',
    };

    const hr =  {
        borderColor: '#E5E5E5',
        margin: '15px 0 0 0',
    };
    
    const titleChild = {
        margin: '16px 5px 0 5px',
        fontSize: '15px',
        fontWeight: 'bold',
        color: '#888787'
    }

    return(
        <Html>
            <Head />
            <Body style={main}>
                <Container style={container}>
                    <Text style={titleAp}>{t("APPLICATION")}</Text>
                    <Row style={rowFirst}>
                        <Column style={{width: '33%'}}>
                            <Text style={title}>{t("AGENT_FIRST_NAME")}</Text>
                            <Text style={paragraph}>{form.form["agent_first_name"]}</Text>  
                        </Column>
                        <Column style={{width: '34%'}}>
                            <Text style={title}>{t("AGENT_LAST_NAME")}</Text>
                            <Text style={paragraph}>{form.form["agent_last_name"]}</Text>  
                        </Column>
                        <Column style={{width: '33%'}}>
                            <Text style={title}>{t("AGENT_EMAIL")}</Text>
                            <Text style={paragraph}>{form.form["agent_email"]}</Text>  
                        </Column>
                    </Row>

                    <Hr style={hr}></Hr>

                    <Row style={row}>
                        <Column style={{width: '33%'}}>
                            <Text style={title}>{t("FIRST_NAME")}</Text>
                            <Text style={paragraph}>{form.form["first_name"]}</Text>  
                        </Column>
                        <Column style={{width: '34%'}}>
                            <Text style={title}>{t("LAST_NAME")}</Text>
                            <Text style={paragraph}>{form.form["last_name"]}</Text>  
                        </Column>
                        <Column style={{width: '33%'}}>
                            <Text style={title}>{t("DATE_OF_BIRTH")}</Text>
                            <Text style={paragraph}>{usFormat(form.form["date_of_birth"])}</Text>  
                        </Column>
                    </Row>

                    <Row style={row}>
                        <Column style={{width: '33%'}}>
                            <Text style={title}>{t("GENDER")}</Text>
                            <Text style={paragraph}>{GenderLabel(form.form["gender"])}</Text>  
                        </Column>
                        <Column style={{width: '34%'}}>
                            <Text style={title}>{t("SOCIAL_SECURITY_NUMBER")}</Text>
                            <Text style={paragraph}>{form.form["social_security_number"]}</Text>  
                        </Column>
                        <Column style={{width: '33%'}}>
                            <Text style={title}>{t("EMPLOYER_NAME")}</Text>
                            <Text style={paragraph}>{form.form["employer_name"]}</Text>  
                        </Column>
                    </Row>

                    <Row style={row}>
                        <Column style={{width: '33%'}}>
                            <Text style={title}>{t("YEARLY_INCOME")}</Text>
                            <Text style={paragraph}>{form.form["yearly_income"]}</Text>  
                        </Column>
                    </Row>

                    <Hr style={hr}></Hr>  

                    <Row style={row}>
                        <Column style={{width: '100%'}}>
                            <Text style={title}>{t("4_OR_MORE_TOBACCO_CLIENT")}</Text>
                            <Text style={paragraph}>{form.form["tobacco"]}</Text>  
                        </Column>
                    </Row>

                    <Row style={row}>
                        <Column style={{width: '34%'}}>
                            <Text style={title}>{t("IMMIGRATION_STATUS")}</Text>
                            <Text style={paragraph}>{form.form["immigration_status"]}</Text>  
                        </Column>
                        <Column style={{width: '33%'}}>
                            <Text style={title}>{t("PHONE")}</Text>
                            <Text style={paragraph}>+{form.form["phone"]}</Text>  
                        </Column>
                        <Column style={{width: '33%'}}>
                            <Text style={title}>{t("EMAIL")}</Text>
                            <Text style={paragraph}>{form.form["email"]}</Text>  
                        </Column>
                    </Row>

                    <Row style={row}>
                        <Column style={{width: '34%'}}>
                            <Text style={title}>{t("ADDRESS")}</Text>
                            <Text style={paragraph}>{form.form["address"]}</Text>  
                        </Column>
                        <Column style={{width: '33%'}}>
                            <Text style={title}>{t("CITY")}</Text>
                            <Text style={paragraph}>{form.form["city"]}</Text>  
                        </Column>
                        <Column style={{width: '33%'}}>
                            <Text style={title}>{t("STATE")}</Text>
                            <Text style={paragraph}>{form.form["state"]}</Text>  
                        </Column>
                    </Row>

                    <Row style={row}>
                        <Column style={{width: '34%'}}>
                            <Text style={title}>{t("ZIP_CODE")}</Text>
                            <Text style={paragraph}>{form.form["zip_code"]}</Text>  
                        </Column>
                        <Column style={{width: '33%'}}>
                            <Text style={title}>{t("MARITAL_STATUS")}</Text>
                            <Text style={paragraph}>{MaritalStatusLabel(form.form["marital_status"])}</Text>  
                        </Column>
                        <Column style={{width: '33%'}}>
                        </Column>
                    </Row>

                    <Hr style={hr}></Hr>

                    {
                        form.form["spouse"] &&
                        <Container>
                            <Row style={row}>
                                <Column style={{width: '33%'}}>
                                    <Text style={title}>{t("SPOUSE_FIRST_NAME")}</Text>
                                    <Text style={paragraph}>{form.form["spouse"]["first_name"]}</Text>  
                                </Column>
                                <Column style={{width: '34%'}}>
                                    <Text style={title}>{t("SPOUSE_LAST_NAME")}</Text>
                                    <Text style={paragraph}>{form.form["spouse"]["last_name"]}</Text> 
                                </Column>
                                <Column style={{width: '33%'}}>
                                    <Text style={title}>{t("SPOUSE_DOB")}</Text>
                                    <Text style={paragraph}>{usFormat(form.form["spouse"]["dob"])}</Text> 
                                </Column>
                            </Row>

                            <Row style={row}>
                                <Column style={{width: '100%'}}>
                                    <Text style={title}>{t("SPOUSE_USED_TOBACCO")}</Text>
                                    <Text style={paragraph}>{form.form["spouse"]["tobacco"]}</Text>  
                                </Column>
                            </Row>

                            <Row style={row}>
                                <Column style={{width: '33%'}}>
                                    <Text style={title}>{t("SPOUSE_IMMIGRATION_STATUS")}</Text>
                                    <Text style={paragraph}>{form.form["spouse"]["immigration_status"]}</Text>  
                                </Column>
                                <Column style={{width: '34%'}}>
                                    <Text style={title}>{t("SPOUSE_EMPLOYMENT")}</Text>
                                    <Text style={paragraph}>{form.form["spouse"]["employment"]}</Text> 
                                </Column>
                                <Column style={{width: '33%'}}>
                                    <Text style={title}>{t("SPOUSE_YEARLY_INCOME")}</Text>
                                    <Text style={paragraph}>{form.form["spouse"]["yearly_income"]}</Text> 
                                </Column>
                            </Row>

                            <Row style={row}>
                                <Column style={{width: '33%'}}>
                                    <Text style={title}>{t("SPOUSE_EMPLOYERS_NAME")}</Text>
                                    <Text style={paragraph}>{form.form["spouse"]["employer_name"]}</Text>  
                                </Column>
                                <Column style={{width: '34%'}}>
                                    <Text style={title}>{t("SPOUSE_SOCIAL_SECURITY_NUMBER")}</Text>
                                    <Text style={paragraph}>{form.form["spouse"]["ssn"]}</Text> 
                                </Column>
                                <Column style={{width: '33%'}}>
                                    <Text style={title}>{t("SPOUSE_INCLUDED_POLICY")}</Text>
                                    <Text style={paragraph}>{form.form["spouse"]["included"]}</Text> 
                                </Column>
                            </Row>

                            <Hr style={hr}></Hr>
                        </Container>
                    }

                    <Row style={row}>
                        <Column style={{width: '100%'}}>
                            <Text style={title}>{t("ARE_YOU_CLAIMING_DEPENDENTS")}</Text>
                            <Text style={paragraph}>{form.form["dependents_claimed"]}</Text>  
                        </Column>
                    </Row>

                    {
                        children.map((child: any, index: number) => {
                            return(
                                <Container>
                                    <Text style={titleChild}>{t("CHILD")} {index + 1} {t("DETAILS")}</Text>
                                    <Row style={rowChild}>
                                        <Column style={{width: '33%'}}>
                                            <Text style={title}>{t("CHILD_FIRST_NAME")}</Text>
                                            <Text style={paragraph}>{form.form["dependents"][index]["first_name"]}</Text>  
                                        </Column>
                                        <Column style={{width: '34%'}}>
                                            <Text style={title}>{t("CHILD_LAST_NAME")}</Text>
                                            <Text style={paragraph}>{form.form["dependents"][index]["last_name"]}</Text>  
                                        </Column>
                                        <Column style={{width: '33%'}}>
                                            <Text style={title}>{t("CHILD_DOB")}</Text>
                                            <Text style={paragraph}>{usFormat(form.form["dependents"][index]["date_of_birth"])}</Text>  
                                        </Column>
                                    </Row>

                                    <Row style={rowChild}>
                                        <Column style={{width: '33%'}}>
                                            <Text style={title}>{t("CHILD_SSN")}</Text>
                                            <Text style={paragraph}>{form.form["dependents"][index]["social_security_number"]}</Text>  
                                        </Column>
                                        <Column style={{width: '34%'}}>
                                            <Text style={title}>{t("CHILD_IMMIGRATION_STATUS")}</Text>
                                            <Text style={paragraph}>{form.form["dependents"][index]["immigration_status"]}</Text>  
                                        </Column>
                                        <Column style={{width: '33%'}}>
                                            <Text style={title}>{t('CHILD_INCLUDED_POLICY')}</Text>
                                            <Text style={paragraph}>{form.form["dependents"][index]["included_policy"]}</Text>  
                                        </Column>
                                    </Row>
                                </Container>
                            )
                        })
                    }

                    <Hr style={hr}></Hr>

                    <Row style={row}>
                        <Column style={{width: '50%'}}>
                            <Text style={title}>{t('BANK_CREDIT_CARD_INFORMATION')}</Text>
                            <Text style={paragraph}>{form.form["bank_credit_information"]}</Text>  
                        </Column>
                        <Column style={{width: '50%'}}>
                            <Text style={title}>{t('UPLOAD_BULK_ONE')}</Text>
                            <Text style={paragraph}>{form.form["upload_bulk_one"]}</Text>  
                        </Column>
                    </Row>
                </Container>
            </Body>
        </Html>
    )
}
