import i18next from "i18next";

export enum RoleEnum {
    ADMIN = 1,
    AGENT,
    SUPER_ADMIN
}

export const RoleLabel = (key: string) => ({ 
    [RoleEnum.ADMIN]: i18next.t('ADMIN'),
    [RoleEnum.AGENT]: i18next.t('AGENT'),
    [RoleEnum.SUPER_ADMIN]: i18next.t('SUPER_ADMIN')
})[key];

export const getRoles = (roleId: number) => {
    const roles = Object.values(RoleEnum).filter(x => !isNaN(Number(x)));

    if(roleId === RoleEnum.ADMIN) {
        const newRoles = roles.filter(element => element !== 3);
        return newRoles.map(element => {
            const value = `${element}`
            return {
                key: element,
                value: value,
                label: RoleLabel(value) ?? ""
            }
        });
    } else if (roleId === RoleEnum.SUPER_ADMIN) {
        return roles.map(element => {
            const value = `${element}`
            return {
                key: element,
                value: value,
                label: RoleLabel(value) ?? ""
            }
        });
    } else {
        return [];
    }
}