import i18next from "i18next";

export enum ImmigrationTypeEnum {
    CITIZEN = 1,
    RESIDENT,
    ASYLUM,
    WORK_AUTHORIZATION
}

export const ImmigrationTypeLabel = (key: string) => ({ 
    [ImmigrationTypeEnum.CITIZEN]: i18next.t('CITIZEN'),
    [ImmigrationTypeEnum.RESIDENT]: i18next.t('RESIDENT'),
    [ImmigrationTypeEnum.ASYLUM]: i18next.t('ASYLUM'),
    [ImmigrationTypeEnum.WORK_AUTHORIZATION]: i18next.t('WORK_AUTHORIZATION')
})[key];

export const getImmigrationTypes = () =>
    Object.values(ImmigrationTypeEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: ImmigrationTypeLabel(value) ?? ""
        }
    });