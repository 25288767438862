import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { axiosClient } from "../axiosClient";
import { ClientNote, NewClientNote } from "../../models/ClientNotes";

export const fetchNotesByClientId = createAsyncThunk("clientNotes", async (params: {clientId: number}) => {
    const response: AxiosResponse<ClientNote[]> = await (await axiosClient()).get(`/clients/${params.clientId}/notes`);
    return response.data
});

export const createClientNote = createAsyncThunk("clientNotes/create", async (params:{clietnNote: NewClientNote,clientId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<ClientNote> = await (await axiosClient()).post(`/clients/${params.clientId}/notes`, {...params.clietnNote});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});