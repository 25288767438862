import i18next from "i18next";

export enum EmployeeTypeEnum {
    EMPLOYED = 1,
    SELF_EMPLOYED,
    UNEMPLOYED,
    RECEIVING_SOCIAL_SECURITY
}

export const EmployeeTypeLabel = (key: string) => ({ 
    [EmployeeTypeEnum.EMPLOYED]: i18next.t('EMPLOYED'),
    [EmployeeTypeEnum.UNEMPLOYED]: i18next.t('UNEMPLOYED'),
    [EmployeeTypeEnum.SELF_EMPLOYED]: i18next.t('SELF_EMPLOYED'),
    [EmployeeTypeEnum.RECEIVING_SOCIAL_SECURITY]: i18next.t('RECEIVING_SOCIAL_SECURITY')
})[key];

export const getEmployeeTypes = () =>
    Object.values(EmployeeTypeEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: EmployeeTypeLabel(value) ?? ""
        }
    });