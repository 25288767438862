import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { createCommissionRuleType, getCommissionRuleTypes, updateCommissionRuleType } from "../../../state/commissions/action";
import { AppDispatch } from '../../../state/store';
import { t } from "i18next";
import FilteredTable from "../../../components/FilteredTable";
import { dateFormat } from "../../../utils/functions";
import Button from "../../../components/Button";
import { CurrencyDollarIcon, PencilIcon, PlusCircleIcon, TrashIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { Modal } from "../../../components/Modal";
import { ReactiveFormInput } from "../../../components/form/ReactiveFormInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { ReactiveFormSelect } from "../../../components/form/ReactiveFormSelect";
import { CommissionRuleType } from "../../../models/CommissionRule";
import { Toast } from "../../../components/Toast";
import { ReactiveFormInputArea } from "../../../components/form/ReactiveFormInputArea";
import { ReactiveFormCheckbox } from "../../../components/form/ReactiveFormCheckbox";

type Props = {
    dataLoadApiUrl : string | undefined
};

export const CommissionRuleTypesMetadataView = (props: Props) => {
    const commissionRuleTypes = useSelector((state: any) => state.commissionRules.state);
    const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
    const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
    const [isCreatingRuleType, setIsCreatingRuleType] = useState(false);
    const [isUpdatingRuleType, setIsUpdatingRuleType] = useState(false);
    const [selectedCommissionRuleType, setSelectedCommissionRuleType] = useState<CommissionRuleType | null>(null);

    const error = useSelector((state: any) => state.commissionRules.error);

    const dispatch = useDispatch<AppDispatch>();

    const createMethods = useForm<any>({
        defaultValues: {}
      });
    const { handleSubmit: handleCreateSubmit, control: createControl } = createMethods;

    const updateMethods = useForm<any>({
        defaultValues: {}
      });
    const { handleSubmit: handleUpdateSubmit, control: updateControl, setValue: setUpdateValue } = updateMethods;

    const showCreateRuleModal = () => {
        return <Modal isOpen={isOpenCreateModal} onClose={() => setIsOpenCreateModal(false)} label={t("NEW_COMMISSION_RULE_TYPE")} onSave={handleCreateSubmit(onCreateSubmit)}>
            <div className='w-full flex flex-wrap'>
                <ReactiveFormInput
                    control={createControl}
                    label={t("NAME")}
                    name="name"
                    isRequired />
            </div>
            <div>    
                <ReactiveFormInputArea
                    control={createControl}
                    label={t("DESCRIPTION")}
                    name="description"
                    isRequired />
            </div>
        </Modal>
    }

    const showEditRuleModal = () => {
        return <Modal isOpen={isOpenUpdateModal} onClose={() => setIsOpenUpdateModal(false)} 
            label={t("EDIT_COMMISSION_RULE_TYPE")} onSave={handleUpdateSubmit(onUpdateSubmit)}>
            <div className='w-full flex flex-wrap'>
                <ReactiveFormInput
                    control={updateControl}
                    label={t("NAME")}
                    name="name"
                    isRequired />
            </div>
            <div>    
                <ReactiveFormInputArea
                    control={updateControl}
                    label={t("DESCRIPTION")}
                    name="description"
                    isRequired />
            </div>
            <div>
                <ReactiveFormCheckbox
                    options={[
                        {label: t("IS_ACTIVE"), value: "true"}
                    ]}
                    control={updateControl}
                    name="is_active"
                />
            </div>
        </Modal>
    }

    const onCreateSubmit: SubmitHandler<CommissionRuleType> = async (data: any) => {
        setIsCreatingRuleType(true);
        
        const newRuleType: CommissionRuleType = {
            name: data.name,
            description: data.description
        }

        dispatch(createCommissionRuleType({apiPath: props.dataLoadApiUrl!, body: newRuleType})).then((e) => {
          if (e.type === "commissions/createCommissionRuleType/rejected") {
            Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info);
            setIsCreatingRuleType(false);
          } else {
            Toast(t("SAVED_SUCCESFULLY"));
            setIsOpenCreateModal(false);
            setIsCreatingRuleType(false);
          }
        })
      };


    useEffect(() => {
        if (commissionRuleTypes.length === 0 || error) {
            dispatch(getCommissionRuleTypes({apiPath: props.dataLoadApiUrl!, includeInactive: true }))
        }
    }, [])

    const openUpdateRuleModal = (row: any) => {
        updateMethods.setValue("name", row.name)
        updateMethods.setValue("description", row.description)
        updateMethods.setValue("is_active", [String(row.is_active)]);
        setSelectedCommissionRuleType(row);
        setIsOpenUpdateModal(true);
    };

    const onUpdateSubmit = (data: any) => {
        console.log(data)
        setIsUpdatingRuleType(true);

        const body: CommissionRuleType = {
            name: data.name,
            description: data.description,
            is_active: data.is_active[0] == "true"
        }

        dispatch(updateCommissionRuleType({ apiPath: props.dataLoadApiUrl!, body: body, ruleTypeId: selectedCommissionRuleType!.id!})).then((e) => {
          if (e.type === "commissions/updateCommissionRuleType/rejected") {
            Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info);
            setIsUpdatingRuleType(false);
            updateMethods.reset()
          } else {
            toast(t("SAVED_SUCCESFULLY"));
            setIsOpenUpdateModal(false);
            setIsUpdatingRuleType(false);
            setSelectedCommissionRuleType(null);
            updateMethods.reset()
          }
        })
    };

    const columns = useMemo(() => [
        {accessor: 'id', Header: t('ID')},
        {accessor: 'name', Header: t('NAME')}, 
        {accessor: 'description', Header: t('DESCRIPTION'), Cell: (data: any) => <span style={{whiteSpace: "pre-line"}}>{data.value}</span> },
        {accessor: 'is_active', Header: t('IS_ACTIVE'), Cell: (data: any): string => data.value ? t('YES') : t('NO')},
        {
            Header: " ",
            Cell: (row: any) => {
                return <div className="flex flex-row justify-center divide-x">
                    <Button
                        isTerciary
                        tooltip={t("EDIT")}
                        iconOnly
                        onClick={() => openUpdateRuleModal(row.row.original)} classNames="mr-2"><PencilIcon className="h-5 aspect-square stroke-blue" />
                    </Button>
                </div>
            }
        }
    ], [t])

    return <>
        {showCreateRuleModal()}
        {showEditRuleModal()}
        <FilteredTable 
        data={commissionRuleTypes} 
        columns={columns}
        buttonAction={<Button onClick={() => setIsOpenCreateModal(true)}>
            <span className='flex items-center font-semibold pr-3'>
                <div className='w-8 p-1 aspect-square mr-2'><PlusCircleIcon /></div>
                {t('NEW')}
            </span>
        </Button>}/>
        </>
}