import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientNote } from "../../models/ClientNotes";
import { createClientNote, fetchNotesByClientId } from "./actions";

interface ClientNotesState {
    clientNotes: ClientNote[],
    isLoading: boolean,
    error: string | null
}

const initialState: ClientNotesState = {
    clientNotes: [],
    isLoading: false,
    error: null
}

const clientNotesSlice = createSlice({
    name: "clientNotes",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotesByClientId.pending, (state) => {
                state.isLoading = true;
                state.error = null
            })
            .addCase(fetchNotesByClientId.fulfilled, (state, action: PayloadAction<ClientNote[]>) => {
                state.isLoading = false;
                state.clientNotes = action.payload;
            })
            .addCase(fetchNotesByClientId.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(createClientNote.pending, (state) => {
                state.isLoading = true;
                state.error = null
            })
            .addCase(createClientNote.fulfilled, (state, action: PayloadAction<ClientNote>) => {
                state.isLoading = false;
                state.clientNotes = state.clientNotes.concat(action.payload);
            })
            .addCase(createClientNote.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            })
    }
})

export default clientNotesSlice.reducer;