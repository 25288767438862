import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { axiosClient } from "../axiosClient";
import { CommissionRuleType } from "../../models/CommissionRule";

export const getCommissionRuleTypes = createAsyncThunk('commissions/getCommissionRuleTypes', async (data: {apiPath: string, includeInactive: boolean}) => {
    const response: AxiosResponse<CommissionRuleType[]> = await (await axiosClient()).get(`${data.apiPath}?include_inactive=${data.includeInactive}`);
    return response.data
});

export const createCommissionRuleType = createAsyncThunk('commissions/createCommissionRuleType', async (data: {apiPath: string, body: CommissionRuleType}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<CommissionRuleType> = await (await axiosClient()).post(data.apiPath, data.body);
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateCommissionRuleType = createAsyncThunk('commissions/updateCommissionRuleType', async (data: {apiPath: string,  ruleTypeId: number, body: CommissionRuleType}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<CommissionRuleType> = await (await axiosClient()).patch(`${data.apiPath}/${data.ruleTypeId}`, data.body);
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteCommissionRuleType = createAsyncThunk('commissions/deleteCommissionRuleType', async (data: {apiPath: string, ruleTypeId: number }, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<CommissionRuleType> = await (await axiosClient()).delete(`${data.apiPath}}/${data.ruleTypeId}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});
