import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { axiosClient } from "../axiosClient";
import { DocumentEnvelope, DownloadDocument } from "../../models/Document";

export const fetchDocumentEnvelopes = createAsyncThunk('documentEnvelopes', async (clientId: number) => {
    const response: AxiosResponse<DocumentEnvelope[]> = await (await axiosClient()).get(`/docusign/clients/${clientId}/envelopes`);
    return response.data
});

export const sendClientDocument = createAsyncThunk('sendClientDocument', async (params: {data: any}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocumentEnvelope> = await (await axiosClient()).post(`/docusign/send/envelope`, {...params.data});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const getEnvelopeDownloadDocument = createAsyncThunk('getDownloadDocument', async (envelopeId: string) => {
    const response: AxiosResponse<DownloadDocument> = await (await axiosClient()).get(`/docusign/download/document/${envelopeId}`);
    return response.data;
});

export const voidClientDocument = createAsyncThunk('voidClientDocument', async (params: {documentId: number, clientId: number, data: any}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<DocumentEnvelope[]> = await (await axiosClient()).put(`/docusign/clients/${params.clientId}/envelopes/${params.documentId}/void`, {...params.data});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});