import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createCommissionRuleType, getCommissionRuleTypes, updateCommissionRuleType, deleteCommissionRuleType } from "./action";
import { CommissionRuleType } from "../../models/CommissionRule";

interface CommissionRuleTypesState {
    state: CommissionRuleType[],
    isLoading: boolean,
    error: string | null,
}

const initialState: CommissionRuleTypesState = {
    state: [],
    isLoading: false,
    error: null,
}

// slice
const commissionRuleTypesSlice = createSlice({
    name: "commissionRuleTypes",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getCommissionRuleTypes.pending, (state) => {
          state.isLoading = true;
          state.state = [];
          state.error = null
        })
        .addCase(getCommissionRuleTypes.fulfilled, (state, action: PayloadAction<CommissionRuleType[]>) => {
          state.isLoading = false;
          state.state = action.payload;
          state.error = null
        })
        .addCase(getCommissionRuleTypes.rejected, (state, action: any) => {
          state.isLoading = false;
          state.state = [];
          state.error = action.payload;
        });

      builder
        .addCase(createCommissionRuleType.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(createCommissionRuleType.fulfilled, (state, action: PayloadAction<CommissionRuleType>) => {
          state.isLoading = false;
          state.state = state.state.concat(action.payload);
        })
        .addCase(createCommissionRuleType.rejected, (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload;
        });

      builder
        .addCase(updateCommissionRuleType.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(updateCommissionRuleType.fulfilled, (state, action: PayloadAction<CommissionRuleType>) => {
          const updatedRule = action.payload;
          state.isLoading = false;
          const idx = state.state.findIndex(x => x.id === updatedRule.id);
          state.state[idx] = updatedRule;
        })
        .addCase(updateCommissionRuleType.rejected, (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload;
        });

      builder
        .addCase(deleteCommissionRuleType.pending, (state) => {
          state.isLoading = true;
          state.error = null
        })
        .addCase(deleteCommissionRuleType.fulfilled, (state, action: PayloadAction<CommissionRuleType>) => {
          const deletedRule = action.payload;
          state.isLoading = false;
          const idx = state.state.findIndex(x => x.id === deletedRule.id);
          state.state.splice(idx, 1);
        })
        .addCase(deleteCommissionRuleType.rejected, (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload;
        });
    },
});
  
export default commissionRuleTypesSlice.reducer;