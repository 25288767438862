import classNames from "classnames";

interface Props {
    name: string
    value?: string | number
    label: string
    onClick?: () => void;
    handleOnChange?: (event: any) => void
    className?: string
    placeholder?: string
    error?:string
    isDisabled?:boolean
}

export const FormInputArea = (props: Props) => {

    return (
        <div className={"w-full px-3 " + props.className ?? ""}>
            <label className="block tracking-wide text-gray-700 text-sm mb-2">
                {props.label}
            </label>
            <textarea
                className={classNames({
                    "appearance-none block w-full border border-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-blue": true,
                    "border-red-500": props.error,
                    "bg-slate-100": props.isDisabled == true
                })}
                placeholder={props.placeholder ?? ""}
                name={props.name}
                onChange={props.handleOnChange}
                value={props.value}
                disabled={props.isDisabled}
                onClick={props.onClick}
            />
            {props.error && <p className="text-red-500 text-xs italic">{props.error}</p>}
        </div>

    );
};