import { User, confirmPasswordReset, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { store } from "../store";
import { removeUser } from "../users/actions";

export default class FirebaseService {

    static async login(email: string, password: string) {
        const auth = getAuth();
        return await signInWithEmailAndPassword(auth, email, password)
    }

    static async logout() {
        store.dispatch(removeUser());
        sessionStorage.removeItem('auth')
        const auth = getAuth();
        await signOut(auth)
    }

    static async getToken() {
        const auth = getAuth()
        let user: User | null = auth.currentUser
        if (!user) { throw Error("USER_NOT_SIGN_IN") }
        return await user.getIdToken();
    }

    static async passwordReset(email: string) {
        const auth = getAuth();
        return await sendPasswordResetEmail(auth, email)
    }
}