import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { axiosClient } from "../axiosClient";
import { EditPolicy, NewPolicy, Policy, PolicyResponse } from "../../models/Policies";

export const fetchPoliciesByClientId = createAsyncThunk("client/policies", async (params: {agencyId: number,clientId: number}) => {
    const response: AxiosResponse<Policy[]> = await (await axiosClient()).get(`/agencies/${params.agencyId}/clients/${params.clientId}/policy`);
    return response.data
});

export const createClientPolicy = createAsyncThunk("client/policies/create", async (params:{policy: NewPolicy,clientId:number,agencyId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Policy> = await (await axiosClient()).post(`/agencies/${params.agencyId}/clients/${params.clientId}/policy`, {...params.policy});
        return {
            ...params.policy,
            id: response.data.id
        };
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateClientPolicy = createAsyncThunk("client/policies/update", async (params:{policy: EditPolicy,clientId:number, agencyId:number}, { rejectWithValue }) => {
    try {
        await (await axiosClient()).patch(`/agencies/${params.agencyId}/clients/${params.clientId}/policy/${params.policy.id}`, {...params.policy});
        return {
            ...params.policy,
            id: params.policy.id ?? 0
        };
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchPolicies = createAsyncThunk("agent/policies", async (data: {page: number, pageSize: number}) => {
    const response: AxiosResponse<PolicyResponse> = await (await axiosClient()).get(`/policies?page=${data.page}&pageSize=${data.pageSize}`);
    return response.data
});

export const updatePolicy = createAsyncThunk("policies/update", async (params:{policy: EditPolicy}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Policy[]> = await (await axiosClient()).patch(`/policies/${params.policy.id}`, {...params.policy});
        return response.data[0];
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const pastPage = createAsyncThunk("agent/policies/pastPage", async () => {
    return true;
});