import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TagResponse } from "../../models/Tags";
import { fetchTagResponses } from "./action";

interface TagResponseState {
    responses: TagResponse[],
    isLoading: boolean,
    error: string | null,
}

const initialState: TagResponseState = {
    responses: [],
    isLoading: false,
    error: null,
}

// slice
const tagResponseSlice = createSlice({
    name: "responses",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchTagResponses.pending, (state) => {
          state.isLoading = true;
          state.responses = [];
          state.error = null
        })
        .addCase(fetchTagResponses.fulfilled, (state, action: PayloadAction<TagResponse[]>) => {
          state.isLoading = false;
          state.responses = action.payload;
          state.error = null
        })
        .addCase(fetchTagResponses.rejected, (state, action: any) => {
          state.isLoading = false;
          state.responses = [];
          state.error = action.payload;
        });
    },
});
  
export default tagResponseSlice.reducer;