import moment from 'moment';

export const longDateFormat = (date: string) => `${moment(date).format('MMM DD YYYY hh:mm')}`;
export const shortDateFormat = (date: string) => `${moment.utc(date).format('MMM DD YYYY')}`;
export const dateFormat = (date: string) => `${moment.utc(date).format('YYYY-MM-DD')}`;
export const usFormat = (date: string) => `${moment.utc(date).format('MM/DD/YYYY')}`;
export const docDateFormat = (date: string) => `${moment.utc(date).format('ll')}`;
export const actualDateFormat = () => `${moment().format('ll')}`;
export const messageDateFormat = (date: string) => {
    const inputDate = moment(date);
    const today = moment();
  
    if (inputDate.isSame(today, 'day')) {
      // If the input date is today, return the time
      return inputDate.format('hh:mm A');
    } else {
      // If the input date is not today, return the formatted date
      return inputDate.format('MM/DD/YYYY');
    }
};
export const isLoggedIn = sessionStorage.getItem('auth')
    ? true
    : false;