interface Props {
    handleOnSubmit: (event:any) => void
    className?:string
    children: any
}

export const Form = (props: Props) => {
    return (
        <form className={"w-full flex flex-wrap " + props.className ?? ""} onSubmit={props.handleOnSubmit}>
            {props.children}
        </form>    
    );
  };