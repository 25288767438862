import i18next from "i18next";

export enum MaritalStatusEnum {
    MARRIED = 1,
    DIVORCED,
    WIDOWED,
    SEPARATED,
    SINGLE,
    REGISTERED_PARTNERSHIP
}

export const MaritalStatusLabel = (key: string) => ({
    [MaritalStatusEnum.SINGLE]: i18next.t('SINGLE'),
    [MaritalStatusEnum.MARRIED]: i18next.t('MARRIED'),
    [MaritalStatusEnum.WIDOWED]: i18next.t('WIDOWED'),
    [MaritalStatusEnum.DIVORCED]: i18next.t('DIVORCED'),
    [MaritalStatusEnum.SEPARATED]: i18next.t('SEPARATED'),
    [MaritalStatusEnum.REGISTERED_PARTNERSHIP]: i18next.t('REGISTERED_PARTNERSHIP')
})[key];

export const getMaritalStatuses = () =>
    Object.values(MaritalStatusEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: MaritalStatusLabel(value) ?? ""
        }
    });