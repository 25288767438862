import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useEffect, useState } from "react";

interface Props {
    name: string
    value?: string | number
    label: string
    onClick?: () => void;
    handleOnChange?: (event: any) => void
    type?: string
    className?: string
    placeholder?: string
    error?:string
    isDisabled?:boolean
    onBlur?: (e: any) => void
}

export const FormInput = (props: Props) => {
    const [isPasswordVisible, setPasswordVisible] = useState(false)

    useEffect(() => {
        setPasswordVisible(props.type === 'password')
    }, [props.type])

    const togglePasswordVisibility = () => {
        setPasswordVisible((prevState) => !prevState);
    }

    const inputType = () => {
        if (props.type === 'password') {
            return isPasswordVisible ? 'password' : 'text'
        }

        return props.type ?? 'text'
    }

    return (
        <div className={"w-full px-3 " + props.className ?? ""}>
            <label className="block tracking-wide text-gray-700 text-sm mb-2">
                {props.label}
            </label>
            <input
                className={classNames({
                    "appearance-none block w-full border border-gray-200 text-gray-700 rounded px-4 mb-3 leading-tight focus:outline-none focus:border-blue": true,
                    "border-red-500": props.error,
                    "bg-slate-100": props.isDisabled == true,
                    "py-3": inputType() != "date",
                    "py-2": inputType() === "date"
                })}
                type={inputType()}
                placeholder={props.placeholder ?? ""}
                name={props.name}
                onChange={props.handleOnChange}
                value={props.value}
                disabled={props.isDisabled}
                onClick={props.onClick}
                onBlur={props.onBlur}
            />
            {props.type === 'password' &&
                <button
                    className="absolute inset-y-0 right-6 mt-4  flex items-center text-black"
                    onClick={togglePasswordVisibility} >
                    {isPasswordVisible ? (
                        <EyeSlashIcon className="w-6 h-6" />
                    ) : (
                        <EyeIcon className="w-6 h-6" />
                    )}
                </button>
            }
            {props.error && <p className="text-red-500 text-xs italic">{props.error}</p>}
        </div>

    );
};