import axios from 'axios';
import FirebaseService from './firebase/FirebaseService';

export const publicAxiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });
  

export const axiosClient = async () => {
  try{
    const token = await FirebaseService.getToken()
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

  } catch(error) {
    return publicAxiosClient
  }
  
}