import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { LoadingMask } from "../../components/LoadingMask";
import FilteredTable from "../../components/FilteredTable";
import { useEffect, useMemo, useState } from "react";
import { RoleEnum } from "../../enums/Role";
import Button from "../../components/Button";
import { FunnelIcon, PaperAirplaneIcon, PencilIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { AppDispatch } from "../../state/store";
import { PolicyFilterModal } from "./PolicyFilterModal";
import { fetchPolicies, pastPage, updatePolicy } from "../../state/policies/actions";
import { shortDateFormat } from "../../utils/functions";
import { Modal } from "../../components/Modal";
import { useForm } from "react-hook-form";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { EditPolicy, Policy } from "../../models/Policies";
import { Toast } from "../../components/Toast";
import { toast, ToastContainer } from "react-toastify";

interface Props {
}

export const Policies = (props: Props) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const pagePolicies = useSelector((state: any) => state.policies.pagePolicies);
    const paginatedOptions = useSelector((state: any) => state.policies.paginatedOptions);
    const isLoading = useSelector((state: any) => state.policies.isLoadingPolicies);
    const error = useSelector((state: any) => state.policies.errorPolicies);
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false)
    const [isUpdatePolicyModalOpen, setIsUpdatePolicyModalOpen] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState<Policy|null>(null)
    const dispatch = useDispatch<AppDispatch>();
    const [showUpdateError,setShowUpdateError] = useState(false);
    const [isPolicyUpdateLoading, setIsPolicyUpdateLoading] = useState(false);
    const errorUpdate = useSelector((state: any) => state.policies.errorUpdate);

    useEffect(() => {
        if(showUpdateError){
            Toast(t(errorUpdate.reason) ?? t("SOMETHING_WENT_WRONG"), errorUpdate.cause_info);
        }
    },[showUpdateError, errorUpdate, t]);

    const columns = useMemo(() => {
        const showColumns = [
            {
                Header: t('NUMBER'),
                accessor: "number",
            },
            {
                Header: t('CARRIER'),
                Cell: (row: any) => t(`${row.row.original.carrier}`),
            },
            {
                Header: t('CLIENT'),
                accessor: "client",
            },
            {
                Header: t('EFFECTIVE_START_DATE'),
                Cell: (row: any) => shortDateFormat(`${row.row.original.effective_start_date}`),
            },
            {
                Header: t('STATUS'),
                Cell: (row: any) => "Pending",
            }
        ];

        const actions = {
            Header: " ",
            Cell: (row: any) => {
                return <div className="flex flex-row justify-center divide-x">
                    <Button
                        isTerciary
                        tooltip={t("UPDATE_POLICY")}
                        iconOnly
                        onClick={() => openUpdatePolicyModal(row.row.original)} classNames="mr-2"><PencilIcon className="h-5 aspect-square stroke-blue" />
                    </Button>
                </div>
            }
        };

        if (currentUser?.role_id !== RoleEnum.AGENT) {
            return [...showColumns, {
                Header: t('AGENT'),
                Cell: (row: any) => `${row.row.original.agent ?? ""}`,
            }, actions]
        }
        return [...showColumns, actions];
    }, [t, currentUser]);

    const getPaginationOptions = useMemo(() => {
        return {
            page: paginatedOptions.page,
            pageSize: paginatedOptions.pageSize,
            totalPages: paginatedOptions.totalPages,
            totalCount: paginatedOptions.totalCount,
            setPage: (value: number) => {
                value < paginatedOptions.page
                 ? dispatch(pastPage())
                 : dispatch(fetchPolicies({page: value, pageSize: paginatedOptions.pageSize}));
            },
            setPageSize: (value: number) => dispatch(fetchPolicies({page: paginatedOptions.page, pageSize: value})),
        }
    }, [dispatch, paginatedOptions]);

    const updateMethods = useForm<any>({
        defaultValues: {}
    });
    const { handleSubmit: handleSubmit, control: control, setValue: setValue } = updateMethods;

    const openUpdatePolicyModal = (policy: Policy) => {
        setValue("number", policy.number);
        setValue("carrier", policy.carrier);
        setValue("client", policy.client);
        setValue("agent", policy.agent);
        setValue("effective_start_date", policy.effective_start_date);
        setValue("effective_end_date", policy.effective_end_date);
        setValue("agent_commission_rule", policy.agent_commission_rule_id);

        setSelectedPolicy(policy)
        setIsUpdatePolicyModalOpen(true);
    };

    const closeUpdatePolicyModal = () => {
        setIsUpdatePolicyModalOpen(false);
        setSelectedPolicy(null);
    };

    const updatePolicyModal = () => {
        return <Modal isOpen={isUpdatePolicyModalOpen} onClose={closeUpdatePolicyModal} label={t("UPDATE_POLICY")} saveButton={{ label: t("SAVE"), icon: <PaperAirplaneIcon />, onClick: handleSubmit(onUpdatePolicy) }}>
          {isPolicyUpdateLoading && <LoadingMask />}
          <div className='w-full flex flex-wrap'>
            <ReactiveFormInput
                control={control}
                className='md:w-1/3'
                label={t("NUMBER")}
                name={`number`}
                isRequired
            />
            <ReactiveFormInput
                control={control}
                className='md:w-1/3'
                label={t("CARRIER")}
                name={`carrier`}
                isDisabled={true}
            />
            <ReactiveFormInput
                control={control}
                className='md:w-1/3'
                label={t("CLIENT")}
                name={`client`}
                isDisabled={true}
            />
            {
                currentUser?.role_id !== RoleEnum.AGENT &&
                <ReactiveFormInput
                    control={control}
                    className='md:w-1/3'
                    label={t("AGENT")}
                    name={`agent`}
                    isDisabled={true}
                />
            }
            <ReactiveFormInput
              control={control}
              className='md:w-1/3'
              isDisabled={true}
              label={t("AGENT_COMMISSION_RULE")}
              name={`agent_commission_rule`}
            />
            <ReactiveFormInput
              control={control}
              type='date'
              className='md:w-1/3'
              isDisabled={true}
              label={t("EFFECTIVE_START_DATE")}
              name={`effective_start_date`}
            />
            <ReactiveFormInput
              control={control}
              type='date'
              className='md:w-1/3'
              label={t("EFFECTIVE_END_DATE")}
              name={`effective_end_date`}
            />
          </div>
        </Modal>
    };

    const onUpdatePolicy = (data: any) => {
        setIsPolicyUpdateLoading(true);
        console.log(data);

        const editPolicy: EditPolicy = {
            id: selectedPolicy?.id,
            number: data.number,
            effective_end_date: data.effective_end_date
        };
        console.log(editPolicy);
    
        dispatch(updatePolicy({ policy: editPolicy })).then((e) => {
            if (e.type === "policies/update/rejected") {
                setShowUpdateError(true);
                setIsPolicyUpdateLoading(false);
            } else {
                toast(t("SAVED_SUCCESFULLY"));
                setIsPolicyUpdateLoading(false);
                closeUpdatePolicyModal();
                //navigate(ClientsPath)
            }
        })
    }
    
    return (<>
        {isLoading && <LoadingMask />}
        {updatePolicyModal()}
        <ToastContainer progressStyle={{ "background": "#D4AF37" }} />
        <FilteredTable
            columns={columns} 
            data={pagePolicies}
            isPaginated
            paginationOptions={getPaginationOptions}
            hasSearchBar={false}
            /*secondaryActionButton={
                <Button onClick={() => {}}>
                    <span className='flex items-center font-semibold pr-3'>
                        <div className='w-8 p-1 aspect-square mr-2'><UserPlusIcon /></div>
                        {t('NEW_POLICY')}
                    </span>
                </Button>
            }
            buttonAction={<Button onClick={() => setFilterModalOpen(true)} iconOnly classNames="w-9 p-1 aspect-square mr-4 ">
                <FunnelIcon className=""></FunnelIcon></Button>}
                */
        />
        <PolicyFilterModal isFilterModalOpen={isFilterModalOpen} setFilterModalOpen={() => setFilterModalOpen(false)} />
    </>
    );
}