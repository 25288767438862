import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { Client, EditClient, NewClientModel, ClientResponse, ClientsFilters } from "../../models/Client";
import { axiosClient, publicAxiosClient } from "../axiosClient";
import { LeadStatusEnum } from "../../enums/LeadStatus";
import { EditLead } from "../../models/Lead";

export const fetchClients = createAsyncThunk("clients/getClients", async (data: {page: number, pageSize: number, filters: ClientsFilters}) => {
    const response: AxiosResponse<ClientResponse> = await (await axiosClient()).patch(`/clients?page=${data.page}&pageSize=${data.pageSize}`, {...data.filters});
    return {
        ...response.data,
        filters: data.filters
    };
});

export const fetchClientById = createAsyncThunk("clients/getClientById", async (id: number) => {
    const response: AxiosResponse<Client> = await (await axiosClient()).get(`/clients/${id}`);
    return response.data;
});

export const createClient = createAsyncThunk("clients/createClient", async (client: NewClientModel, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Client> = await (await axiosClient()).post(`/clients`, {...client});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const createClientFromSurvey = createAsyncThunk("clients/createClient/survey", async (params:{client: NewClientModel, agentId: string}, { rejectWithValue }) => {
    try{
        const response: AxiosResponse<Client> = await publicAxiosClient.post(`/clients_survey?agentId=${params.agentId}`, {...params.client});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateClient = createAsyncThunk("clients/updateClient", async (params: {client: EditClient, clientId: number,agentId:number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Client[]> = await (await axiosClient()).patch(`/clients/${params.clientId}`, {...params.client});
        return response.data[0];
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteClient = createAsyncThunk("clients/deleteClient", async (params: {clientId: number, page: number, pageSize: number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Client> = await (await axiosClient()).delete(`/clients/${params.clientId}`);
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const createClientFromLead = createAsyncThunk("leads/createClientFromLead", async (params: { lead: EditLead, leadId: number }, { rejectWithValue }) => {
    try {
        await (await axiosClient()).post(`/clients/leads/${params.leadId}`);
        return {
            ...params.lead,
            status_id: LeadStatusEnum.CLIENT,
            id: params.leadId
        };
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const pastPage = createAsyncThunk("clients/pastPage", async () => {
    return true;
});

export const clearForm = createAsyncThunk("clients/clearForm", async () => {
    return true;
});
