import classNames from "classnames";
import {useMemo} from 'react';

interface Props {
    name: string
    selectedValue?: string | number
    label: string
    handleOnChange?: (event:any) => void
    options: {value: string, label: string}[]
    className?:string
    isRequired?: boolean
    width?: string
    placeholder?: string
    isDisabled?:boolean
    error?:string
    onBlur?: (e: any) => void
}

export const FormSelect = (props: Props) => {
    const selectedValue = useMemo(() => {
        return (props.selectedValue === "0" || !props.selectedValue || props.selectedValue === undefined) ? "" : props.selectedValue
    }, [props.selectedValue]);
    return (
        <div className={`px-3 ${props.className ?? ""} ${props.width ?? "w-full"}`}>
            <label className="block tracking-wide text-gray-700 text-sm mb-2">
              {props.label}
            </label>
            <div className="relative">
                <select
                    className={classNames({
                        "appearance-none block w-full border border-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-blue": true,
                        "border-red-500": props.error,
                        "bg-slate-100": props.isDisabled == true
                    })}
                    disabled={props.isDisabled}
                    //placeholder={props.placeholder ?? ""}
                    name={props.name}
                    value={selectedValue ?? ""}
                    onBlur={props.onBlur}
                    onChange={props.handleOnChange ?? (() => {})}
                >
                    <option disabled hidden label="" value="" className={`${props.width ?? "w-full"} p-3 hover:bg-gray-50`}></option>
                    {props.options.map(option => 
                        <option key={option.value} value={option.value} className={`${props.width ?? "w-full"} p-3 hover:bg-gray-50`}>{option.label}</option>
                    )}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
             </div>
             {props.error && <p className="text-red-500 text-xs italic">{props.error}</p>}
          </div>
          
    );
  };