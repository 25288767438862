// ProgressBar.tsx
import React from 'react';

interface ProgressBarProps {
  currentStep: number;
  stepsLabels: string[]
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep, stepsLabels }) => {
  const segmentWidth = 100 / stepsLabels.length;

  return (
    <div className="w-full text-center mb-4 relative">
        
        {stepsLabels.map((label:string, index:number) => (
          <div
            key={index}
            className={`absolute -top-5 left-0 transform -translate-x-1/2 -translate-y-4 text-center transition-all duration-300 w-full  md:w-${segmentWidth} ${
              index === currentStep - 1
                ? `md:inline-block ${
                    index < currentStep ? 'font-semibold' : 'text-asureis-gray'
                  }`
                : 'hidden md:inline-block'
            }`}
            style={{
              left: `${index * segmentWidth + segmentWidth / 2}%`,
            }}
          >
            {label}
          </div>
        ))}
      <div className="w-full bg-gray-300 h-2 rounded-full relative rounded-lg overflow-hidden">
      {stepsLabels.map((_, index) => (
          <div
            key={index}
            className={`h-full absolute transition-all duration-300  ${
              index < currentStep ? 'bg-blue' : 'asureis-gray'
            }`}
            style={{
              width: `${segmentWidth}%`,
              left: `${index * segmentWidth}%`,
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
