import {
    PencilIcon
} from "@heroicons/react/24/outline";
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import Button from "../../components/Button";
import FilteredTable from "../../components/FilteredTable";
import { LoadingMask } from '../../components/LoadingMask';
import { FormCheckbox } from "../../components/form/FormCheckbox";

export const Documents = () => {
    const { t } = useTranslation()
    const clients = useSelector((state: any) => state.clients.clients);
    const isLoading = useSelector((state: any) => state.clients.isLoading);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

    const handleChange = useCallback((row: any) => {
        if (row) {
            const index = selectedFiles.findIndex(x => x === row);
            if (index > -1) {
                const currentSelectedFiles = selectedFiles.filter((x, currentIndex) => currentIndex !== index);
                setSelectedFiles(currentSelectedFiles);
            } else {
                const currentSelectedFiles = selectedFiles;
                currentSelectedFiles.push(row as any);
                setSelectedFiles(currentSelectedFiles);
            }
        }
    }, [selectedFiles]);

    const columns = useMemo(
        () => [
            {
                id: "select",
                Header: '',
                Cell: (row: any) => (<FormCheckbox name="select" handleOnChange={() => handleChange(row.row.original)} />)
            },
            {
                Header: t('NAME'),
                accessor: "name",
            },
            {
                Header: t('DESCRIPTION'),
                accessor: "description",
            }
        ],
        [t, handleChange]
    );

    return (<>
        {isLoading && <LoadingMask />}
        <FilteredTable
            columns={columns}
            data={clients}
            buttonAction={<Button isDisabled={selectedFiles.length < 1} onClick={() => alert("CLICK")}>
                <span className='flex items-center font-semibold pr-3'>
                    <div className='w-8 p-1 aspect-square mr-2'><PencilIcon /></div>
                    {t('SEND_TO_CLIENT')}
                </span>
            </Button>}
        />
    </>
    )
}