import i18next from "i18next";

export enum ConversationType {
    CLIENTS,
    LEADS,
    OTHER
}

export const ConversationTypeLabel = (key: string) => ({ 
    [ConversationType.CLIENTS]: i18next.t('CLIENT'),
    [ConversationType.LEADS]: i18next.t('LEAD'),
    [ConversationType.OTHER]: i18next.t('OTHER')
})[key];

export const getClientLeadTypes = () =>
    Object.values(ConversationType).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: ConversationTypeLabel(value) ?? ""
        }
    });