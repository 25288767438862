import { CheckIcon, PencilIcon, PlusIcon, StarIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Button from '../../components/Button';
import { LoadingMask } from '../../components/LoadingMask';
import { Modal } from '../../components/Modal';
import Table from '../../components/Table';
import { Toast } from '../../components/Toast';
import { Form } from '../../components/form/Form';
import { ReactiveFormInput } from '../../components/form/ReactiveFormInput';
import { ReactiveFormPhoneInput } from '../../components/form/ReactiveFormPhoneInput';
import { ClientPublicFormPath, ClientsPath } from '../../constants/Routes';
import { RoleLabel } from '../../enums/Role';
import { EditUser } from '../../models/User';
import { AppDispatch } from '../../state/store';
import { createUserTwilioNumber, setMainUserTwilioNumber, updateUserTwilioNumber } from '../../state/users/actions';
import { updateMainTwilioPhone, updateUser } from '../../state/users/actions';
import { ReactiveFormSelect } from '../../components/form/ReactiveFormSelect';
import states from 'states-us/dist';
import { getBusiness } from '../../enums/Business';

export const Profile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const dispatch = useDispatch<AppDispatch>();
    const isLoading = useSelector((state: any) => state.users.isLoading);
    const error = useSelector((state: any) => state.users.error);
    const [isOpenAddModal, setIsOpenAddModal] = useState(false);
    const [isAddingPhone, setIsAddingPhone] = useState(false);
    const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
    const [isUpdatingPhone, setIsUpdatingPhone] = useState(false);
    const [selectedPhone, setSelectedPhone] = useState<any | null>(null);
    const [isTwilioPhonesLoading, setIsTwilioPhonesLoading] = useState(false);
    const twilioPhones = useSelector((state: any) => state.users.twilioPhones);
    const twilioError = useSelector((state: any) => state.users.error);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (showError) {
            Toast(t(twilioError.reason) ?? t("SOMETHING_WENT_WRONG"), twilioError.cause_info)
            setShowError(false);
        }
    }, [showError, twilioError, t]);

    useEffect(() => {
        if (twilioPhones?.length === 1 && currentUser?.main_twilio_number_id !== twilioPhones[0].id) {
            onSetMainPhoneSubmit(twilioPhones[0], false);
        }
    }, [twilioPhones, currentUser]);

    const methods = useForm<EditUser>({
        defaultValues: currentUser
    });
    const { handleSubmit, control, reset } = methods;

    const addPhoneMethods = useForm<any>({
        defaultValues: {}
    });
    const { handleSubmit: handleAddPhoneSubmit, control: addPhoneControl, setValue: setAddPhoneValue } = addPhoneMethods;

    const updatePhoneMethods = useForm<any>({
        defaultValues: {}
    });
    const { handleSubmit: handleUpdatePhoneSubmit, control: updatePhoneControl, setValue: setUpdatePhoneValue } = updatePhoneMethods;

    useEffect(() => {
        reset(currentUser);
    }, [currentUser, reset]);

    const onSubmit: SubmitHandler<EditUser> = async (data: EditUser) => {
        const updatedData = data;
        if (data.role_id) {
            updatedData.role_id = +data.role_id;
        }
        if (data.commission_level) {
            updatedData.commission_level = +data.commission_level;
        }
        if(data.business_id) {
            updatedData.business_id = +data.business_id;
        }
        dispatch(updateUser({ user: updatedData, agencyId: currentUser.agency_id })).then((e) => {
            if (e.type === "users/updateUser/rejected") {
                Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info);
            } else {
                toast(t("SAVED_SUCCESFULLY"));
                navigate(ClientsPath)
            }
        })
    };

    const getClientSurveyURL = useMemo(() => {
        if (currentUser) {
            let path = window.location.origin + ClientPublicFormPath;
            path = path.replace(":id", currentUser.id);
            path = path.replace(":name", `${currentUser.first_name} ${currentUser.last_name}`);
            path = path.replace(":npn", currentUser.national_producer_number);
            return path;
        }
    }, [currentUser]);

    const copyClientSurvey = () => {
        navigator.clipboard.writeText(getClientSurveyURL ?? "");
        toast(t("URL_COPIED"));
    }

    const openAddPhoneModal = () => {
        setIsOpenAddModal(true);
    };

    const closeAddPhoneModal = () => {
        setIsOpenAddModal(false);
    };

    const getStates = useMemo(() => {
        return states.map(state => {
            return {
                label: state.name,
                value: state.abbreviation,
                key: state.abbreviation
            }
        })
    }, []);

    const showAddPhoneModal = () => {
        return <Modal isOpen={isOpenAddModal} onClose={closeAddPhoneModal} label={t("ADD_PHONE")} onSave={handleAddPhoneSubmit(onAddPhoneSubmit)} >
            {(isAddingPhone || isTwilioPhonesLoading) && <LoadingMask />}
            <div className='w-full flex flex-wrap'>
                <ReactiveFormInput
                    control={addPhoneControl}
                    className='md:w-1/2'
                    name="label"
                    label={t("LABEL")}
                    isRequired
                />
                <ReactiveFormSelect
                    className="md:w-1/2"
                    name="state"
                    label={t("STATE")}
                    options={getStates}
                    control={addPhoneControl}
                    isRequired
                />
            </div>
        </Modal>
    }

    const onAddPhoneSubmit: SubmitHandler<any> = async (data: any) => {
        setIsAddingPhone(true);
        data["agent_id"] = currentUser.id;
        dispatch(createUserTwilioNumber(data)).then((e) => {
            if (e.type === "createTwilioNumber/rejected") {
                setShowError(true);
                setIsAddingPhone(false);
            } else {
                toast(t("SAVED_SUCCESFULLY"));
                closeAddPhoneModal();
                setIsAddingPhone(false);
            }
        })
    }

    const openUpdatePhoneModal = (phone: any) => {
        setSelectedPhone(phone);
        setIsOpenUpdateModal(true);
        setUpdatePhoneValue("label", phone.label);
        setUpdatePhoneValue("twilio_phone", phone.twilio_phone);
    };

    const closeUpdatePhoneModal = () => {
        setIsOpenUpdateModal(false);
        setUpdatePhoneValue("label", "");
        setUpdatePhoneValue("twilio_phone", "");
    };

    const showUpdatePhoneModal = () => {
        return <Modal isOpen={isOpenUpdateModal} onClose={closeUpdatePhoneModal} label={t("EDIT_PHONE")} onSave={handleUpdatePhoneSubmit(onUpdatePhoneSubmit)}>
            {(isUpdatingPhone || isTwilioPhonesLoading) && <LoadingMask />}
            <div className='w-full flex flex-wrap'>
                <ReactiveFormInput
                    control={updatePhoneControl}
                    className='md:w-1/2'
                    name="label"
                    label={t("LABEL")}
                    isRequired
                />
                <ReactiveFormPhoneInput
                    control={updatePhoneControl}
                    className='md:w-1/2'
                    name="twilio_phone"
                    label={t("TWILIO_PHONE")}
                    isRequired
                    isDisabled
                />
            </div>
        </Modal>
    }

    const onUpdatePhoneSubmit = (data: any) => {
        setIsUpdatingPhone(true);
        data["id"] = selectedPhone!.id;
        data["agent_id"] = currentUser.id;

        dispatch(updateUserTwilioNumber({ twilioNumberId: selectedPhone!.id, data: data })).then((e) => {
            if (e.type === "updateTwilioNumber/rejected") {
                setShowError(true);
                setIsUpdatingPhone(false);
            } else {
                toast(t("SAVED_SUCCESFULLY"));
                if (selectedPhone.id === currentUser?.main_twilio_number_id) {
                    dispatch(updateMainTwilioPhone(data));
                }
                closeUpdatePhoneModal();
                setIsUpdatingPhone(false);
                setSelectedPhone(null);
            }
        })
    }

    const onSetMainPhoneSubmit = (phone: any, showToast: boolean) => {
        dispatch(setMainUserTwilioNumber(phone.id)).then((e) => {
            if (e.type === "setMainTwilioNumber/rejected") {
                setShowError(true);
            } else {
                if (showToast) {
                    toast(t("SAVED_SUCCESFULLY"));
                }
                dispatch(updateMainTwilioPhone(phone));
            }
        })
    }

    const getUserTwilioPhones = useMemo(() => {
        return twilioPhones?.map((phone: any) => {

            return {
                columns: [
                    { value: phone.label },
                    { value: phone.twilio_phone },
                    {
                        value: <td className="flex flex-row justify-end divide-x">
                            <Button
                                isTerciary
                                iconOnly
                                isDisabled={currentUser?.main_twilio_number_id === phone.id}
                                tooltip={t("SET_AS_MAIN_PHONE")}
                                onClick={() => onSetMainPhoneSubmit(phone, true)} classNames="mr-2"><StarIcon className="h-5 aspect-square stroke-gold" />
                            </Button>
                            <Button
                                isTerciary
                                iconOnly
                                tooltip={t("EDIT_PHONE")}
                                onClick={() => openUpdatePhoneModal(phone)} classNames="mr-2 justify-self-end"><PencilIcon className="h-5 aspect-square stroke-blue" />
                            </Button>
                        </td>
                    }
                ]
            }
        });
    }, [currentUser, twilioPhones, t]);

    return (
        <div className="w-full grow flex flex-col px-5 space-y-4">
            {isLoading && <LoadingMask />}
            {showAddPhoneModal()}
            {showUpdatePhoneModal()}
            <ToastContainer progressStyle={{ "background": "#D4AF37" }} />
            <div className="text-black max-h-full p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden">
                <div className="text-xl font-bold text-black flex justify-between pl-3">
                    {t("MY_PROFILE")}
                    <Button onClick={copyClientSurvey}>{t("CLIENT_SURVEY_URL")}</Button>
                </div>
                <div className="pb-5">
                    <Form handleOnSubmit={handleSubmit(onSubmit)}>
                        <div className='w-full text-lg font-semibold p-3'>{t("PERSONAL_INFORMATION")}</div>
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("FIRST_NAME")}
                            name="first_name"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("LAST_NAME")}
                            name="last_name"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("SOCIAL_SECURITY_NUMBER")}
                            name="social_security_number"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            type='date'
                            className='md:w-1/3'
                            label={t("DATE_OF_BIRTH")}
                            name="date_of_birth"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("EMAIL")}
                            name="email"
                            type='email'
                            isDisabled />
                        <ReactiveFormPhoneInput
                            control={control}
                            className='md:w-1/3'
                            name="phone"
                            label={t("PHONE")}
                            isRequired
                        />
                        <div className='w-full text-lg font-semibold p-3'>{t("AGENT_INFORMATION")}</div>
                        <div className={"w-full px-3 md:w-1/3"}>
                            <label className="block tracking-wide text-gray-700 text-sm mb-2">
                                {t("ROLE")}
                            </label>
                            <label className={"appearance-none block w-full text-gray-700 rounded py-3 px-4 mb-3 leading-tight"}>
                                {RoleLabel(currentUser?.role_id ?? "")}
                            </label>
                        </div>
                        <ReactiveFormSelect
                            className="md:w-1/3"
                            name={`business_id`}
                            label={t("BUSINESS")}
                            options={getBusiness()}
                            control={control}
                            isRequired
                        />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("NATIONAL_PRODUCER_NUMBER")}
                            name={`national_producer_number`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("AKA_LICENSE")}
                            name={`aka_license`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("UPLINE")}
                            name={`upline`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("OVERRIDES")}
                            name={`overrides`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("COMMISSION_LEVEL")}
                            name={`commission_level`}
                            type='number'
                        />
                        <div className='flex justify-between w-full'>
                            <div className='w-full text-lg font-semibold p-3 self-center'>{t("PHONES")}</div>
                            { /*<div className='text-end p-3'>
                                <Button
                                    iconOnly
                                    classNames='h-10 w-10'
                                    tooltip={t("ADD_PHONE")}
                                    onClick={openAddPhoneModal}><PlusIcon className="h-4 w-4 ml-1" />
                                </Button>
                            </div> */}
                        </div>
                        <div className='w-full'>
                            <Table
                                columns={[
                                    { header: `${t('LABEL')}` },
                                    { header: `${t('PHONE')}` },
                                    { header: "", }
                                ]}
                                data={getUserTwilioPhones}
                            />
                        </div>
                    </Form>
                </div>
            </div>
            <div className='pb-5 text-right'>
                <Button onClick={handleSubmit(onSubmit)}>
                    <span className='flex items-center font-semibold pr-3'>
                        <div className='w-8 p-1 aspect-square mr-2'><CheckIcon /></div>
                        {t('SAVE')}
                    </span>
                </Button>
            </div>
        </div>
    );
};