import i18next from "i18next";

export enum RelationshipEnum {
    SPOUSE = 1,
    CHILD
}

export const RelationshipLabel = (key: string) => ({ 
    [RelationshipEnum.SPOUSE]: i18next.t('SPOUSE'),
    [RelationshipEnum.CHILD]: i18next.t('CHILD')
})[key];

export const getRelationships = () =>
    Object.values(RelationshipEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: RelationshipLabel(value) ?? ""
        }
    });