import {
    ChatBubbleBottomCenterIcon,
    DocumentArrowDownIcon,
    DocumentArrowUpIcon,
    PencilIcon,
    PhoneIcon,
    PlayIcon,
    TrashIcon,
    UserPlusIcon
} from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import {
    useNavigate,
} from "react-router-dom";
import Button from "../../components/Button";
import FilteredTable from "../../components/FilteredTable";
import { LoadingMask } from '../../components/LoadingMask';
import { ConversationsPath, NewLeadPath, UpdateLeadPath } from '../../constants/Routes';
import { LeadStatusEnum, LeadStatusLabel } from "../../enums/LeadStatus";
import { Modal } from "../../components/Modal";
import { ReactiveFormUploadFile } from "../../components/form/ReactiveFormUploadFile";
import { useForm } from "react-hook-form";
import * as xlsx from 'xlsx';
import { Lead, NewUploadLead } from "../../models/Lead";
import { AppDispatch } from "../../state/store";
import { ToastContainer, toast } from "react-toastify";
import { startLeadAutomation, uploadLeads } from "../../state/leads/actions";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { Toast } from '../../components/Toast';
import { TwilioDeviceState } from "../../enums/TwilioDeviceState";
import { createConversation, fetchConversationMessages, fetchConversations } from "../../state/twilio/actions";
import { ConversationType } from "../../enums/ConversationType";
import uploadLeadTemplate from '../../assets/templates/uploadLeadTemplate.xlsx';

interface Props {
    makePhoneCall: (phone: string) => void;
}
export const Leads = (props: Props) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const leads = useSelector((state: any) => state.leads.leads);
    const isLoading = useSelector((state: any) => state.leads.isLoading);
    const error = useSelector((state: any) => state.leads.error);
    const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
    const [isUploadingLeads, setIsUploadingLeads] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const [isOpenStartAutomationModal, setIsOpenStartAutomationModal] = useState(false);
    const [isStartingAutomation, setIsStartingAutomation] = useState(false);
    const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
    const automations = useSelector((state: any) => state.automations.automations);
    const twilioChatState = useSelector((state: any) => state.twilio.areTwilioCredentialsValid);
    const deviceState = useSelector((state: any) => state.twilio.twilioDeviceState);
    const twilio = useSelector((state: any) => state.twilio);
    const [showError,setShowError] = useState(false);

    useEffect(() => {
        if(showError){
            Toast(t(error?.reason ?? t("SOMETHING_WENT_WRONG")), error?.cause_info);
        }
    },[showError, error, t]);

    const methods = useForm<{}>();
    const { control } = methods;

    const startMethods = useForm<{}>();
    const { handleSubmit: handleStartSubmit, control: startControl } = startMethods;

    const makeConversation = useCallback((phone: string) => {
        if (twilio.conversations && currentUser) {
            const selectedConversation = twilio.conversations.find((x: any) => x.friendlyName === phone);
            if (selectedConversation) {
                dispatch(fetchConversationMessages({ conversationSid: selectedConversation.sid }));
            } else {
                dispatch(createConversation({newConversation: { from: currentUser.main_twilio_number?.twilio_phone, to: phone }, conversationType: ConversationType.LEADS}))
            }
            navigate(ConversationsPath);
        }
    }, [twilio.conversations, currentUser, dispatch, navigate]);

    const columns = useMemo(
        () => [
            {
                Header: t('NAME'),
                Cell: (row: any) => {
                    const { first_name, last_name } = row.row.original;
                    return `${first_name ?? ""} ${last_name ?? ""}`;
                },
            },
            {
                Header: t('PHONE'),
                accessor: "phone",
            },
            {
                Header: t('EMAIL'),
                accessor: "email",
            },
            {
                Header: t('STATUS'),
                Cell: (row: any) => LeadStatusLabel(`${row.row.original.status_id}`)
            },
            {
                Header: " ",
                Cell: (row: any) => {
                    return <div className="flex flex-row justify-center divide-x">
                        <Button
                            isTerciary
                            isDisabled={canRunAutomation(row.row.original)}
                            iconOnly
                            tooltip={t("RUN_AUTOMATION")}
                            onClick={() => openStartAutomationModal(row.row.original)} classNames="mr-2"><PlayIcon className="h-5 aspect-square stroke-gold" />
                        </Button>
                        <Button
                            isTerciary
                            isDisabled={!twilioChatState}
                            tooltip={t("START_CONVERSATION")}
                            iconOnly
                            onClick={() => makeConversation(row.row.original.phone)} classNames="pl-2"><ChatBubbleBottomCenterIcon className="h-5 aspect-square stroke-gold" />
                        </Button>
                        <Button
                            isTerciary
                            isDisabled={deviceState !== TwilioDeviceState.READY}
                            tooltip={t("CALL_LEAD")}
                            iconOnly
                            onClick={() => props.makePhoneCall(row.row.original.phone)} classNames="pl-2"><PhoneIcon className="h-5 aspect-square stroke-gold" />
                        </Button>
                        <Button
                            isTerciary
                            iconOnly
                            tooltip={t("UPDATE_LEAD")}
                            onClick={() => updateLead(row.row.original)} classNames="mr-2"><PencilIcon className="h-5 aspect-square stroke-blue" />
                        </Button>
                    </div>
                }
            }
        ],
        [t, twilioChatState, deviceState]
    );

    const canRunAutomation = (row: any) => {
        if (row.status_id === LeadStatusEnum.CLIENT) {
            return true;
        } else {
            const conversation = twilio.conversations.find((conv: any) => conv.friendlyName === row.phone);
            if (conversation !== undefined) {
                if (conversation.attributes["isInAutomation"]) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    };

    const updateLead = (row: any) => {
        navigate(UpdateLeadPath, { state: { lead: row } });
    };

    const openUploadLeadsModal = () => {
        setIsOpenUploadModal(true);
    };

    const closeUploadLeadsModal = () => {
        setIsOpenUploadModal(false);
        setSelectedFile(null);
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = link.href = uploadLeadTemplate;
        link.download = 'uploadLeadsTemplate.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    const showUploadLeadsModal = () => {
        return <Modal thirdButton={{
            label: t("UPLOAD_EXAMPLE"),
            onClick: () => handleDownload(),
            icon: <DocumentArrowDownIcon />
        }} isOpen={isOpenUploadModal} onClose={closeUploadLeadsModal} label={t('UPLOAD_LEADS')} saveButton={{ label: t("UPLOAD"), icon: <DocumentArrowUpIcon />, onClick: handleSubmitSendDocument }}>
            {isUploadingLeads && <LoadingMask />}
            <div className='w-full flex flex-wrap'>
                {!selectedFile && <ReactiveFormUploadFile
                    control={control}
                    className='md:w-1/1'
                    label={t("LEADS_FILE")}
                    name="leads_file"
                    handleOnChange={handlePlanQuotedFiles} />
                }
                {selectedFile && <div className="w-full px-4 mb-3">
                    <div className="flex justify-between">
                        <label className="text-base font-semibold self-center">{selectedFile.name}</label>
                        <Button iconOnly classNames='h-7 w-7 self-end' onClick={handleRemoveUploadLeadFile}><TrashIcon className="h-3 w-3" /></Button>
                    </div>
                </div>
                }
            </div>
        </Modal>
    }

    const handlePlanQuotedFiles = (e: any) => {
        setSelectedFile(e[0])
    }

    const handleRemoveUploadLeadFile = () => {
        setSelectedFile(null);
    }

    const handleSubmitSendDocument = async () => {
        setIsUploadingLeads(true);
        const reader = new FileReader();
        reader.readAsBinaryString(selectedFile);
        reader.onload = (e: any) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "binary", cellDates: true, cellNF: false, cellText: false });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = xlsx.utils.sheet_to_json(sheet);
            uploadFileLeads(parsedData);
        }
    }

    const uploadFileLeads = async (xlsxData: any[]) => {
        const leads = xlsxData.map((row) => {
            const d: NewUploadLead = {
                first_name: row['FirstName'],
                last_name: row['LastName'],
                dob: `${row['DOB']}`,
                phone: `+${row['Phone']}`,
                email: row['Email'],
                address: row['Address'],
                state: row['State'],
                city: row['City'],
                zip_code: `${row['ZipCode']}`,
                source_id: row['Source'],
                referred_by: row['ReferredBy'],
                referred_amount: row['ReferredAmount'],
                automation_code: row['AutomationCode']
            }
            return d;
        });

        dispatch(uploadLeads(leads)).then((e) => {
            if (e.type === "leads/uploadLead/rejected") {
                setShowError(true);
                setIsUploadingLeads(false);
            } else {
                toast(t("SAVED_SUCCESFULLY"));
                closeUploadLeadsModal();
                setIsUploadingLeads(false);
                //navigate(LeadsPath)
            }
        });
    }

    const openStartAutomationModal = (lead: Lead) => {
        setSelectedLead(lead);
        setIsOpenStartAutomationModal(true);
    };

    const closeStartAutomationModal = () => {
        setIsOpenStartAutomationModal(false);
        setSelectedFile(null);
    };

    const showRunAutomationModal = () => {
        return <Modal isOpen={isOpenStartAutomationModal} onClose={closeStartAutomationModal} label={t("RUN_AUTOMATION")} onSave={handleStartSubmit(onStartAutomationSubmit)}>
            {isStartingAutomation && <LoadingMask />}
            <div className='w-full flex flex-wrap'>
                <div className="w-full">
                    <ReactiveFormSelect
                        className="md:w-1/1"
                        name={`automation_id`}
                        label={t("AUTOMATION")}
                        options={getAutomations}
                        control={startControl}
                    />
                </div>
            </div>
        </Modal>
    }

    const onStartAutomationSubmit = (data: any) => {
        setIsStartingAutomation(true);

        dispatch(startLeadAutomation({ phone: selectedLead!.phone, automationId: data.automation_id! })).then((e) => {
            if (e.type === "leads/startAutomation/rejected") {
                Toast(t(error?.reason ?? "SOMETHING_WENT_WRONG") , error?.cause_info);
                setIsStartingAutomation(false);
            } else {
                toast(t("AUTOMATION_STARTED_SUCCESFULLY"));
                dispatch(fetchConversations(currentUser.main_twilio_number?.twilio_phone));
                closeStartAutomationModal();
                setIsStartingAutomation(false);
                setSelectedLead(null);
            }
        })
    };

    const getAutomations = useMemo(() => {
        return automations.map((a: any) => {
            return {
                label: `${a.name}`,
                value: `${a.id}`,
                key: a.id
            }
        })
    }, [automations]);

    return (<>
        {isLoading && <LoadingMask />}
        {showUploadLeadsModal()}
        {showRunAutomationModal()}
        <ToastContainer progressStyle={{ "background": "#D4AF37" }} />
        <FilteredTable
            columns={columns}
            data={leads}
            buttonAction={<Button onClick={() => navigate(NewLeadPath)}>
                <span className='flex items-center font-semibold pr-3'>
                    <div className='w-8 p-1 aspect-square mr-2'><UserPlusIcon /></div>
                    {t('SAVE_FOR_LEADS')}
                </span>
            </Button>}
            secondaryActionButton={
                <Button isTerciary={true} classNames="ml-3" onClick={openUploadLeadsModal}>
                    <span className='flex items-center font-semibold pr-3'>
                        <div className='w-8 p-1 aspect-square mr-2'><DocumentArrowUpIcon /></div>
                        {t('UPLOAD_LEADS')}
                    </span>
                </Button>
            }
        />
    </>
    )
}