import {
    ChevronUpIcon
} from "@heroicons/react/24/outline";
import classNames from "classnames";

interface Props {
    blocks: { label: string, info: React.ReactNode, isOpen?: boolean, handleChange: () => void }[]
}

export const Accordion = (props: Props) => {
    return (
        <>
            <div className="w-full grow p-5 flex flex-col">
                <div className="space-y-4">

                    {props.blocks?.map(element => {
                        return <div key={element.label} className={classNames({
                                "text-black p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden": true,
                                "max-h-16": !element.isOpen,
                                "max-h-full": element.isOpen
                            })}>
                            <div className="px-3 pb-3 flex cursor-pointer justify-between items-center " onClick={element.handleChange}>
                                <div className={classNames({
                                    "text-xl font-bold text-black": true,
                                })}>
                                    {element.label}
                                </div>
                                <ChevronUpIcon className={classNames({
                                    "w-6 h-6 transition-all duration-500 ease-in-out": true,
                                    "rotate-180": !element.isOpen,
                                    "rotate-0": element.isOpen
                                })} />
                            </div>
                            <div className="pb-5">
                                {element.info}
                            </div>
                        </div>
                    }
                    )}
                </div>
            </div>
        </>
    );
};