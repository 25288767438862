import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from 'axios';
import { axiosClient } from "../axiosClient";
import { Automation, CreateAutomation, UpdateAutomation } from "../../models/Automation";

export const fetchAutomations = createAsyncThunk("automations", async () => {
    const response: AxiosResponse<Automation[]> = await (await axiosClient()).get(`/automations`);
    return response.data
});

export const fetchAutomationById = createAsyncThunk("automations/byId", async (params:{automationId:number}) => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/automations/${params.automationId}`);
    return response.data
});

export const updateAutomation = createAsyncThunk("automations/update", async (params:{automationId:number,automation: UpdateAutomation}, { rejectWithValue }) => {
    try { 
        await (await axiosClient()).patch(`/automations/${params.automationId}`, {...params.automation});
        return {
            id: params.automationId,
            name: params.automation.name,
            code: params.automation.code
        }
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const createAutomation = createAsyncThunk("automations/create", async (automation: CreateAutomation, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<any> = await (await axiosClient()).post(`/automations`, {...automation});
        return {
            id: response.data.id,
            name: automation.name,
            code: automation.code
        }
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});