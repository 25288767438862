import { configureStore } from '@reduxjs/toolkit'
import clientsReducer from './clients/slice'
import usersReducer from './users/slice'
import leadsReducer from './leads/slice';
import addressReducer from './addresses/slice';
import incomeSourcesReducer from './incomeSources/slice';
import automationReducer from './automations/slice';
import twilioReducer from './twilio/slice';
import policiesReducer from './policies/slice';
import dependentsReducer from './dependents/slice';
import templateReducer from './templates/slice';
import documentReducer from './documents/slice';
import emailReducer from './email/slice';
import agenciesReducer from './agencies/slice';
import tagsReducer from './tags/slice';
import responseReducer from './tagResponses/slice';
import dashboardReducer from './dashboard/slice';
import clientNotesReducer from './clientNotes/slice';
import metadataMenuItemsSliceReducer from './metadata/slice';
import commissionRulesSliceReducer from './commissions/slice';
import policyLeadboardSliceReducer from './policyLeadboard/slice';

export const store = configureStore({
  reducer: {
    clients: clientsReducer,
    users: usersReducer,
    leads: leadsReducer,
    addresses: addressReducer,
    incomeSources: incomeSourcesReducer,
    automations: automationReducer,
    twilio: twilioReducer,
    policies: policiesReducer,
    dependents: dependentsReducer,
    templates: templateReducer,
    documents: documentReducer,
    emails: emailReducer,
    agencies: agenciesReducer,
    tags: tagsReducer,
    tagResponses: responseReducer,
    dashboard: dashboardReducer,
    clientNotes: clientNotesReducer,
    menuItems: metadataMenuItemsSliceReducer,
    commissionRules: commissionRulesSliceReducer,
    policiesLeadboard: policyLeadboardSliceReducer
  },
  middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore specific paths
                ignoredPaths: ['twilio.device'],
                // Ignore specific actions
                ignoredActions: ['twilioDevice/fulfilled'],
            },
        }),
});



// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch