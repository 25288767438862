import i18next from "i18next";

export enum ClientFormStepEnum {
    PERSONAL_DETAILS = 1,
    EMPLOYMENT_INFORMATION,
    DEPENDANTS,
    DOCTOR,
    ADDITIONAL_INFORMATION
}

export const ClientFormStepLabel = (key: string) => ({ 
    [ClientFormStepEnum.PERSONAL_DETAILS]: i18next.t('PERSONAL_DETAILS'),
    [ClientFormStepEnum.EMPLOYMENT_INFORMATION]: i18next.t('EMPLOYMENT_INFORMATION'),
    [ClientFormStepEnum.DEPENDANTS]: i18next.t('DEPENDANTS'),
    [ClientFormStepEnum.DOCTOR]: i18next.t('DOCTOR'),
    [ClientFormStepEnum.ADDITIONAL_INFORMATION]: i18next.t('ADDITIONAL_INFORMATION')
})[key];

export const getClientFormSteps = () =>
    Object.values(ClientFormStepEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: ClientFormStepLabel(value) ?? ""
        }
    });