import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchIncomeSourcesByClientId } from "./actions";
import { IncomeSource } from "../../models/IncomeSource";

interface IncomeSourceState {
  incomeSources: IncomeSource[],
  isLoading: boolean,
  error: string | null
}

const initialState: IncomeSourceState = {
  incomeSources: [],
  isLoading: false,
  error: null
}

// slice
const incomeSourcesSlice = createSlice({
  name: "leads",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncomeSourcesByClientId.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchIncomeSourcesByClientId.fulfilled, (state, action: PayloadAction<IncomeSource[]>) => {
        state.isLoading = false;
        state.incomeSources = action.payload;
      })
      .addCase(fetchIncomeSourcesByClientId.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default incomeSourcesSlice.reducer;