import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

interface Props {
    name: string
    checked?: boolean
    label?: string
    handleOnChange: (event: any) => void
    className?: string
    isRequired?: boolean
    placeholder?: string
    isDisabled?: boolean
    inlineDisplay?: boolean
    tooltip?: string
    isBig?: boolean
}

export const FormCheckbox = (props: Props) => {
    return (
        <div className={"w-full px-3 " + props.className ?? ""}>
            {props.label && !props.inlineDisplay &&
                <div className="flex gap-2">
                    <label className="block tracking-wide text-gray-700 text-sm mb-2">
                        {props.label}
                    </label>
                    {props.tooltip &&
                        <div className="group relative self-center">
                            <QuestionMarkCircleIcon className="w-5 h-5" />
                            <span className="p-2 bg-blue text-white pointer-events-none absolute bottom-7 rounded-lg right-0 w-max opacity-0 transition-opacity group-hover:opacity-100 max-w-[300px]">
                                {props.tooltip}
                            </span>
                        </div>
                    }
                </div>
            }
            <div className={classNames({ "flex items-center": props.inlineDisplay })} >
                <input
                    className={classNames({
                        "accent-white hover:accent-gold hover:text-white cursor-pointer": true,
                        "cursor-auto": props.isDisabled,
                    })}
                    type={"checkbox"}
                    name={props.name}
                    onChange={props.handleOnChange}
                    disabled={props.isDisabled}
                    checked={props.checked}
                />
                {props.inlineDisplay &&
                    <div className="flex gap-2">
                        <span className={classNames({
                            "ml-2 text-gray-700": true,
                            "text-sm tracking-wide": !props.isBig,
                            "text-lg tracking-normal": props.isBig
                        })}>{props.label}</span>
                        {props.tooltip &&
                            <div className="group relative self-center">
                                <QuestionMarkCircleIcon className="w-5 h-5 text-gray-700" />
                                <span className="p-2 bg-blue text-white pointer-events-none absolute bottom-7 rounded-lg right-0 w-max opacity-0 transition-opacity group-hover:opacity-100 max-w-[300px]">
                                    {props.tooltip}
                                </span>
                            </div>
                        }
                    </div>
                }
            </div>
            {props.isRequired && <p className="text-red-500 text-xs italic">Required</p>}
        </div >

    );
};