import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentEnvelope, DownloadDocument } from "../../models/Document";
import { fetchDocumentEnvelopes, getEnvelopeDownloadDocument, sendClientDocument, voidClientDocument } from "./actions";

interface EnvelopeState {
    envelopes: DocumentEnvelope[],
    isLoading: boolean,
    error: string | null,
    isLoadingDownload: boolean;
    errorDownload: string | null,
}
  
const initialState: EnvelopeState = {
    envelopes: [],
    isLoading: false,
    error: null,
    isLoadingDownload: false,
    errorDownload: null
}

// slice
const documentSlice = createSlice({
    name: 'documents',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDocumentEnvelopes.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchDocumentEnvelopes.fulfilled, (state, action: PayloadAction<DocumentEnvelope[]>) => {
                state.isLoading = false;
                state.envelopes = action.payload;
            })
            .addCase(fetchDocumentEnvelopes.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            });
        builder
            .addCase(sendClientDocument.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(sendClientDocument.fulfilled, (state, action: PayloadAction<DocumentEnvelope>) => {
                state.isLoading = false;
                state.envelopes = state.envelopes.concat(action.payload);
            })
            .addCase(sendClientDocument.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            });
        builder
            .addCase(getEnvelopeDownloadDocument.pending, (state) => {
                state.isLoadingDownload = true;
                state.errorDownload = null;
            })
            .addCase(getEnvelopeDownloadDocument.fulfilled, (state, action: PayloadAction<DownloadDocument>) => {
                state.isLoadingDownload = false;
                state.errorDownload = null;
            })
            .addCase(getEnvelopeDownloadDocument.rejected, (state, action: any) => {
                state.isLoadingDownload = false;
                state.errorDownload = action.payload;
            })
        builder
            .addCase(voidClientDocument.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(voidClientDocument.fulfilled, (state, action: PayloadAction<DocumentEnvelope[]>) => {
                state.isLoading = false;
                state.envelopes = action.payload;
            })
            .addCase(voidClientDocument.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    }
});

export default documentSlice.reducer;