import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Automation } from "../../models/Automation";
import { fetchAutomations, fetchAutomationById, updateAutomation, createAutomation } from "./action";

interface AutomationState {
  automations: Automation[],
  isLoading: boolean,
  error: string | null,
  currentAutomation?: any
}

const initialState: AutomationState = {
  automations: [],
  isLoading: false,
  error: null
}

// slice
const automationSlice = createSlice({
  name: "automations",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAutomations.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchAutomations.fulfilled, (state, action: PayloadAction<Automation[]>) => {
        state.isLoading = false;
        state.automations = action.payload;
      })
      .addCase(fetchAutomations.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAutomationById.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchAutomationById.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.isLoading = false;
        state.currentAutomation = action.payload;
      })
      .addCase(fetchAutomationById.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateAutomation.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateAutomation.fulfilled, (state, action: PayloadAction<any>) => {
        const updatedAutomation = action.payload;
        const updatedAutomations = state.automations.map((automation) => {
          return automation.id === updatedAutomation.id ? updatedAutomation : automation
        }
        );
        state.automations = updatedAutomations;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateAutomation.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createAutomation.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createAutomation.fulfilled, (state, action: PayloadAction<any>) => {
        state.automations = state.automations.concat(action.payload);
        state.isLoading = false;
        state.error = null;
      })
      .addCase(createAutomation.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default automationSlice.reducer;