import classNames from 'classnames'
import React from 'react'
import { Controller } from 'react-hook-form'

interface Props {
  name: string
  value?: string // Updated value type to string
  label: string
  className?: string
  error?: string
  options: { label: string; value: string }[]
  control: any
  isRequired?: boolean,
  isDisabled?: boolean
}

export const ReactiveFormRadioButton = (props: Props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{ required: props.isRequired ?? false }}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <div className={"w-full mb-3 px-3 grid items-end " + (props.className ?? "")}>
          <label className="block tracking-wide text-gray-700 text-sm mb-2">
            {props.label}
          </label>
          <div className='flex gap-4'>
            {props.options.map((option) => (
              <div className="form-check" key={option.value}>
                <label
                  htmlFor={option.value}
                  className="block tracking-wide text-gray-700 text-xs mb-2"
                >
                  <input
                    type="radio"
                    name={props.name}
                    onChange={onChange}
                    checked={value === option.value}
                    value={option.value}
                    className={classNames({
                      "form-check-input mr-2": true,
                      "border-red-500": props.error,
                      "bg-slate-100": props.isDisabled == true
                    })}
                    id={option.value}
                  />
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    />
  );
};
