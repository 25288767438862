import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createUser, createUserTwilioNumber, fetchUser, fetchUserTwilioNumber, fetchUserTwilioPhones, fetchUsers, removeUser, setMainUserTwilioNumber, updateMainTwilioPhone, updateUser, updateUserTwilioNumber } from "./actions";
import { User } from "../../models/User";
import { setCurrentAgency } from "../agencies/action";

interface UserState {
  isLoading: boolean
  error: string | null
  users: User[],
  currentUser: User | null,
  twilioPhones: any[],
  twilioNumber: any | null,
  isPhoneConfigLoading: boolean,
}

const initialState: UserState = {
  isLoading: false,
  error: null,
  users: [],
  currentUser: null,
  twilioPhones: [],
  twilioNumber: null,
  isPhoneConfigLoading: false,
}

// slice
const usersSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.isLoading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.isLoading = false;
        state.users = state.users.concat(action.payload);
      })
      .addCase(createUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.isLoading = false;
        if (state.currentUser?.id !== action.payload.id) {
          state.currentUser = action.payload;
          sessionStorage.setItem('auth', JSON.stringify(action.payload));
        }
      })
      .addCase(fetchUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(removeUser.fulfilled, (state, action: any) => {
        state.currentUser = null;
      })
      .addCase(updateUser.pending, (state, action) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<User>) => {
        const updatedUser = action.payload;
        const updatedUsers = state.users.map((user) => {
          return user.id === updatedUser.id ? updatedUser : user
        }
        );
        state.users = updatedUsers;
        state.isLoading = false;
        state.error = null;

        if (state.currentUser?.id === action.payload.id) {
          state.currentUser = action.payload;
        }
      })
      .addCase(updateUser.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateMainTwilioPhone.fulfilled, (state, action: any) => {
        state.isLoading = false;
        if (state.currentUser) {
          state.currentUser.main_twilio_number = action.payload;
          state.currentUser.main_twilio_number_id = action.payload.id;
        }
      })
      .addCase(fetchUserTwilioPhones.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchUserTwilioPhones.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.isLoading = false;
        state.twilioPhones = action.payload;
      })
      .addCase(fetchUserTwilioPhones.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createUserTwilioNumber.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createUserTwilioNumber.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.twilioPhones = state.twilioPhones.concat(action.payload);
      })
      .addCase(createUserTwilioNumber.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchUserTwilioNumber.pending, (state) => {
        state.isPhoneConfigLoading = true;
        state.error = null
      })
      .addCase(fetchUserTwilioNumber.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.isPhoneConfigLoading = false;
        state.twilioNumber = action.payload;
      })
      .addCase(fetchUserTwilioNumber.rejected, (state, action: any) => {
        state.isPhoneConfigLoading = false;
        state.error = action.payload;
      })
      .addCase(updateUserTwilioNumber.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateUserTwilioNumber.fulfilled, (state, action: PayloadAction<any>) => {
        const updatedPhoneConfig = action.payload;
        const updatedPhones = state.twilioPhones.map((phone) => {
          return phone.id === updatedPhoneConfig.id ? updatedPhoneConfig : phone
        });
        state.twilioPhones = updatedPhones;
        state.isLoading = false;
      })
      .addCase(updateUserTwilioNumber.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(setMainUserTwilioNumber.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(setMainUserTwilioNumber.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.twilioPhones = state.twilioPhones;
      })
      .addCase(setMainUserTwilioNumber.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(setCurrentAgency.rejected, (state, action: any) => {
        if (state.currentUser) {
          state.currentUser.agency = action.payload;
        }
      });
  },
});

export default usersSlice.reducer;