import { createSlice } from "@reduxjs/toolkit";
import { fetchNewClientFromLeads, fetchNewLeads, fetchUnContactedAnsweredLeads } from "./actions";

interface DashboardState {
    isNewLeadLoading: boolean
    isNewClientLoading: boolean
    isUnContactedAnsweredLeadLoading: boolean
    isNewLeadLoaded: boolean
    isNewClientLoaded: boolean
    isUnContactedAnsweredLeadLoaded: boolean
    error: string | null
    newLeads: any[]
    newClientLeads: any[]
    unContactedLeads: any[]
    unAnsweredLeads: any[]
}

const initialState: DashboardState = {
    isNewLeadLoading: false,
    isNewClientLoading: false,
    isUnContactedAnsweredLeadLoading: false,
    isNewLeadLoaded: false,
    isNewClientLoaded: false,
    isUnContactedAnsweredLeadLoaded: false,
    error: null,
    newLeads: [],
    newClientLeads: [],
    unContactedLeads: [],
    unAnsweredLeads: []
}

// slice
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNewLeads.pending, (state) => {
                state.isNewLeadLoading = true;
            })
            .addCase(fetchNewLeads.fulfilled, (state, action: any) => {
                state.isNewLeadLoading = false;
                state.newLeads = action.payload;
                state.isNewLeadLoaded = true;
            })
            .addCase(fetchNewLeads.rejected, (state, action: any) => {
                state.isNewLeadLoading = false;
                state.error = action.payload;
            });
        builder
            .addCase(fetchNewClientFromLeads.pending, (state) => {
                state.isNewClientLoading = true;
            })
            .addCase(fetchNewClientFromLeads.fulfilled, (state, action: any) => {
                state.isNewClientLoading = false;
                state.newClientLeads = action.payload;
                state.isNewClientLoaded = true;
            })
            .addCase(fetchNewClientFromLeads.rejected, (state, action: any) => {
                state.isNewClientLoading = false;
                state.error = action.payload;
            });
        builder
            .addCase(fetchUnContactedAnsweredLeads.pending, (state) => {
                state.isUnContactedAnsweredLeadLoading = true;
            })
            .addCase(fetchUnContactedAnsweredLeads.fulfilled, (state, action: any) => {
                state.isUnContactedAnsweredLeadLoading = false;
                state.unContactedLeads = action.payload.un_contact_results;
                state.unAnsweredLeads = action.payload.un_answered_results;
                state.isUnContactedAnsweredLeadLoaded = true;
            })
            .addCase(fetchUnContactedAnsweredLeads.rejected, (state, action: any) => {
                state.isUnContactedAnsweredLeadLoading = false;
                state.error = action.payload;
            });
    },
});

export default dashboardSlice.reducer;