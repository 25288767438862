import { useEffect } from "react";

export const LoadingMask =() => {

    useEffect(() => {
        // Disable scrolling on the body element
        document.body.style.overflow = 'hidden';
    
        // Cleanup: Re-enable scrolling when the component unmounts
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, []);
      
    return <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-20">
    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-gold"></div>
  </div>
}