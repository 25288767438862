import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DocumentTemplate } from "../../models/Document";
import { fetchDocumentTemplates } from "./actions";

interface TemplateState {
    templates: DocumentTemplate[],
    isLoading: boolean,
    error: string | null,
}

const initialState: TemplateState = {
    templates: [],
    isLoading: false,
    error: null,
}

// slice
const templatesSlice = createSlice({
    name: "templates",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDocumentTemplates.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchDocumentTemplates.fulfilled, (state, action: PayloadAction<DocumentTemplate[]>) => {
                state.isLoading = false;
                state.templates = action.payload;
            })
            .addCase(fetchDocumentTemplates.rejected, (state, action: any) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    }
});

export default templatesSlice.reducer;