import i18next from "i18next";

export enum StepAnswerTypeEnum {
    BOOL_TRUE = 1,
    BOOL_FALSE,
    TEXT,
    NOT_ANSWER
}

export const StepAnswerTypeLabel = (key: string) => ({
    [StepAnswerTypeEnum.BOOL_TRUE]: i18next.t('YES'),
    [StepAnswerTypeEnum.BOOL_FALSE]: i18next.t('NO'),
    [StepAnswerTypeEnum.TEXT]: i18next.t('TEXT'),
    [StepAnswerTypeEnum.NOT_ANSWER]: i18next.t('NOT_ANSWER')
    ,
})[key];

export const getStepAnswerTypes = () =>
    Object.values(StepAnswerTypeEnum).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: StepAnswerTypeLabel(value) ?? ""
        }
    });

export enum AnswerTextType {
    plainText = 1,
    email,
    custom
}

export const getAnswerTextTypes = () =>
    Object.values(AnswerTextType).filter(x => !isNaN(Number(x))).map(element => {
        const value = `${element}`
        return {
            key: element,
            value: value,
            label: AnswerTextTypeLabel(value) ?? ""
        }
    });

export const AnswerTextTypeLabel = (key: string) => ({
    [AnswerTextType.plainText]: i18next.t('PLAIN_TEXT'),
    [AnswerTextType.email]: i18next.t('EMAIL'),
    [AnswerTextType.custom]: i18next.t('CUSTOM'),
})[key];