import { t } from "i18next";
import { FormCheckbox } from "../form/FormCheckbox";
import { ConversationType } from "../../enums/ConversationType";
import { ConversationFilter } from "../../models/ConversationFilter";
import Button from "../Button";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";

export const ConversationFilters = (props: { filters: ConversationFilter, updateFilterValues: (x: ConversationFilter) => void }) => {
    const handleChange = (e: any) => {
        let updatedFilters = { ...props.filters };
        if (e.target.checked) {
            updatedFilters.selectedTypes = [...updatedFilters.selectedTypes, e.target.name];
        } else {
            updatedFilters.selectedTypes = updatedFilters.selectedTypes.filter(x => x !== e.target.name);
        }
        props.updateFilterValues(updatedFilters);
    };

    const clearAll = useCallback(() => {
        props.updateFilterValues({ selectedTypes: [] });
    }, [props]);

    return (
        <div className="fixed top-36 bg-white h-fit w-full max-w-[185px] rounded-md shadow-lg z-10 flex flex-col gap-1">
            <div className="p-2">
                <FormCheckbox
                    checked={props.filters?.selectedTypes.includes(`${ConversationType.CLIENTS}`)}
                    name={"" + ConversationType.CLIENTS}
                    inlineDisplay
                    handleOnChange={handleChange}
                    label={t('CLIENTS')}
                    isBig
                />
                <FormCheckbox
                    checked={props.filters?.selectedTypes.includes(`${ConversationType.LEADS}`)}
                    name={"" + ConversationType.LEADS}
                    inlineDisplay
                    handleOnChange={handleChange}
                    label={t('LEADS')}
                    isBig
                />
                <FormCheckbox
                    checked={props.filters?.selectedTypes.includes(`${ConversationType.OTHER}`)}
                    name={"" + ConversationType.OTHER}
                    inlineDisplay
                    handleOnChange={handleChange}
                    label={t('OTHERS')}
                    isBig
                />
            </div>
            <div className="border-t-2 border-asureis-light-gray px-6 pb-2 w-full">
                <div className="flex justify-end items-center">
                    <Button iconOnly isSecondary onClick={clearAll}>
                        <XMarkIcon className="text-asureis-gray w-6" />
                    </Button>
                    <span className="text-base text-asureis-gray">{t("CLEAR")}</span>
                </div>
            </div>
        </div>
    );
};
