import { Outlet } from "react-router-dom"
import Layout from "../../components/Layout"

export const Home = () => {
    return (
        <>
            <Layout>
                <Outlet />
            </Layout>
        </>
    )
}