import { CheckIcon } from '@heroicons/react/24/outline';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Button from '../../components/Button';
import { LoadingMask } from '../../components/LoadingMask';
import { Form } from '../../components/form/Form';
import { ReactiveFormInput } from '../../components/form/ReactiveFormInput';
import { ReactiveFormPhoneInput } from '../../components/form/ReactiveFormPhoneInput';
import { ReactiveFormSelect } from '../../components/form/ReactiveFormSelect';
import { UsersPath } from '../../constants/Routes';
import { RoleEnum, getRoles } from '../../enums/Role';
import { EditUser } from '../../models/User';
import { AppDispatch } from '../../state/store';
import { updateUser } from '../../state/users/actions';
import { Toast } from '../../components/Toast';
import { getBusiness } from '../../enums/Business';

export const UpdateUser = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const dispatch = useDispatch<AppDispatch>();
    const isLoading = useSelector((state: any) => state.users.isLoading);
    const error = useSelector((state: any) => state.users.error);
    const location = useLocation();
    const user = location.state.user;
    const methods = useForm<EditUser>({
        defaultValues: user
    });
    const { handleSubmit, control } = methods;

    const onSubmit: SubmitHandler<EditUser> = async (data: EditUser) => {
        const updatedData = data;
        if (data.role_id) {
            updatedData.role_id = +data.role_id;
        }
        if (data.commission_level) {
            updatedData.commission_level = +data.commission_level;
        }
        if(data.business_id) {
            updatedData.business_id = +data.business_id;
        }
        dispatch(updateUser({ user: updatedData, agencyId: currentUser.agency_id })).then((e) => {
            if (e.type === "users/updateUser/rejected") {
                Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info);
            } else {
                toast(t("SAVED_SUCCESFULLY"));
                navigate(UsersPath)
            }
        })
    };

    return (
        <div className="w-full grow px-5 space-y-4 flex flex-col">
            {isLoading && <LoadingMask />}
            <ToastContainer progressStyle={{ "background": "#D4AF37" }} />
            <div className="text-black max-h-full p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden">
                <div className="text-xl font-bold text-black pl-3">
                    {t("USER")}
                </div>
                <div className="pb-5">
                    <Form handleOnSubmit={handleSubmit(onSubmit)}>
                        <div className='w-full text-lg font-semibold p-3'>{t("PERSONAL_INFORMATION")}</div>
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("FIRST_NAME")}
                            name="first_name"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("LAST_NAME")}
                            name="last_name"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("SOCIAL_SECURITY_NUMBER")}
                            name="social_security_number"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            type='date'
                            className='md:w-1/3'
                            label={t("DATE_OF_BIRTH")}
                            name="date_of_birth"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("EMAIL")}
                            name="email"
                            type='email'
                            isDisabled />
                        <ReactiveFormPhoneInput
                            control={control}
                            className='md:w-1/3'
                            name="phone"
                            label={t("PHONE")}
                            isRequired
                        />
                        <div className='w-full text-lg font-semibold p-3'>{t("AGENT_INFORMATION")}</div>
                        <ReactiveFormSelect
                            className="md:w-1/3"
                            name={`role_id`}
                            label={t("ROLE")}
                            options={getRoles(currentUser?.role_id)}
                            control={control}
                            isRequired
                        />
                        <ReactiveFormSelect
                            className="md:w-1/3"
                            name={`business_id`}
                            label={t("BUSINESS")}
                            options={getBusiness()}
                            control={control}
                            isRequired
                        />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("NATIONAL_PRODUCER_NUMBER")}
                            name={`national_producer_number`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("AKA_LICENSE")}
                            name={`aka_license`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("UPLINE")}
                            name={`upline`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("OVERRIDES")}
                            name={`overrides`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("COMMISSION_LEVEL")}
                            name={`commission_level`}
                            type='number'
                        />
                    </Form>
                </div>
            </div>
            <div className='pb-5 text-right'>
                <Button onClick={handleSubmit(onSubmit)}>
                    <span className='flex items-center font-semibold pr-3'>
                        <div className='w-8 p-1 aspect-square mr-2'><CheckIcon /></div>
                        {t('SAVE')}
                    </span>
                </Button>
            </div>
        </div>
    );
};