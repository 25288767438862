// components/defaultNavItems.tsx
import {
  ArrowLeftOnRectangleIcon,
  BuildingOfficeIcon,
  ChatBubbleLeftRightIcon,
  ClipboardDocumentListIcon,
  DocumentIcon,
  UserGroupIcon,
  UserIcon,
  ArrowsUpDownIcon,
  PhoneIcon,
  HomeIcon,
  TagIcon,
  QueueListIcon,
  ChartBarSquareIcon,
  CircleStackIcon,
  ShieldCheckIcon,
  CurrencyDollarIcon
} from "@heroicons/react/24/outline";
import { getAuth } from "firebase/auth";
import React from "react";
import { UsersPath, AutomationPath, ClientsPath, ConversationsPath, LeadsPath, PhonePath, AgencyPath, TagsPath, DashboardPath, MetadataPath, CommissionsPath, PoliciesPath } from "../constants/Routes";
import FirebaseService from "../state/firebase/FirebaseService";
import { NavItemKey } from "../enums/NavItemKey";
// define a NavItem prop
export type NavItem = {
  key: number;
  label: string;
  href?: string;
  action?: Function;
  icon: React.ReactNode;
};
export const defaultNavItems: NavItem[] = [
  {
    label: "Dashboard",
    href: DashboardPath,
    key: NavItemKey.DASHBOARD,
    icon: <ChartBarSquareIcon className="w-6 h-6" />,
  },
  {
    label: "Clients",
    href: ClientsPath,
    key: NavItemKey.CLIENTS,
    icon: <UserGroupIcon className="w-6 h-6" />,
  },
  {
    label: "Policies",
    href: PoliciesPath,
    key: NavItemKey.POLICIES,
    icon: <ShieldCheckIcon className="w-6 h-6" />,
  },
  {
    label: "Conversations",
    href: ConversationsPath,
    key: NavItemKey.CONVERSATIONS,
    icon: <ChatBubbleLeftRightIcon className="w-6 h-6" />,
  },
  {
    label: "Phone",
    href: PhonePath,
    key: NavItemKey.PHONE,
    icon: <PhoneIcon className="w-6 h-6" />,
  },
  {
    label: "Leads",
    href: LeadsPath,
    key: NavItemKey.LEADS,
    icon: <UserIcon className="w-6 h-6" />,
  },
  {
    label: "Tags",
    href: TagsPath,
    key: NavItemKey.TAGS,
    icon: <TagIcon className="w-6 h-6" />
  },
  {
    label: "Lead Automations",
    href: AutomationPath,
    key: NavItemKey.LEAD_AUTOMATIONS,
    icon: <QueueListIcon className="w-6 h-6" />,
  }
];

export const adminNavItems: NavItem[] = [
  {
    label: "Users",
    href: UsersPath,
    key: NavItemKey.USERS,
    icon: <BuildingOfficeIcon className="w-6 h-6" />,
  },
  {
    label: "Agency",
    href: AgencyPath,
    key: NavItemKey.AGENCY,
    icon: <HomeIcon className="w-6 h-6" />,
  },
  /*{
    label: "Commissions",
    href: CommissionsPath,
    key: NavItemKey.COMMISSIONS,
    icon: <CurrencyDollarIcon className="w-6 h-6" />,
  },
  {
    label: "Metadata",
    href: MetadataPath,
    key: NavItemKey.METADATA,
    icon: <CircleStackIcon className="w-6 h-6" />,
  }*/
]

export const defaultNavOptions: NavItem[] = [
  {
    label: "Sign Out",
    key: NavItemKey.SIGN_OUT,
    icon: <ArrowLeftOnRectangleIcon className="w-6 h-6" />,
    action: () => FirebaseService.logout()
  }
];