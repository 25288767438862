import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { axiosClient } from "../axiosClient";

export const sendEmail = createAsyncThunk('sendEmail', async (params: {data: any}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<any> = await (await axiosClient()).post(`/email/send`, {...params.data});
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});