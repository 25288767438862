import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { axiosClient } from "../axiosClient";

export const fetchNewLeads = createAsyncThunk("dashboard/newLeads", async (params: {startDate: string, endDate: string}) => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/dashboard/new-leads`, {params: {startDate: params.startDate, endDate: params.endDate}});
    return response.data
});

export const fetchNewClientFromLeads = createAsyncThunk("dashboard/newClientLeads", async (params: {startDate: string, endDate: string}) => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/dashboard/new-client-leads`, {params: {startDate: params.startDate, endDate: params.endDate}});
    return response.data
});

export const fetchUnContactedAnsweredLeads = createAsyncThunk("dashboard/unContactedAnsweredLeads", async (params: {startDate: string, endDate: string}) => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/dashboard/un-contacted-answered-leads`, {params: {startDate: params.startDate, endDate: params.endDate}});
    return response.data
});