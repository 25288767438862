import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AllPolicies, Policy, PolicyResponse } from "../../models/Policies";
import { createClientPolicy, fetchPolicies, fetchPoliciesByClientId, pastPage, updateClientPolicy, updatePolicy } from "./actions";

interface PolicyState {
  clientPolicies: Policy[],
  isLoading: boolean,
  error: string | null
  policies: AllPolicies[],
  pagePolicies: Policy[],
  isLoadingPolicies: boolean,
  errorPolicies: string | null,
  errorUpdate: string | null,
  paginatedOptions: {
    page: number,
    pageSize: number,
    totalPages: number,
    totalCount: number
  },
}

const initialState: PolicyState = {
  clientPolicies: [],
  isLoading: false,
  error: null,
  policies: [],
  pagePolicies: [],
  isLoadingPolicies: false,
  errorPolicies: null,
  errorUpdate: null,
  paginatedOptions: {
    page: 0,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
  }
}

// slice
const policiesSlice = createSlice({
  name: "policies",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPoliciesByClientId.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchPoliciesByClientId.fulfilled, (state, action: PayloadAction<Policy[]>) => {
        state.isLoading = false;
        state.clientPolicies = action.payload;
      })
      .addCase(fetchPoliciesByClientId.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createClientPolicy.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createClientPolicy.fulfilled, (state, action: PayloadAction<Policy>) => {
        state.isLoading = false;
        state.clientPolicies = state.clientPolicies.concat(action.payload);
      })
      .addCase(createClientPolicy.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateClientPolicy.pending, (state, action) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateClientPolicy.fulfilled, (state, action: PayloadAction<Policy>) => {
        const updatedClient = action.payload;
        const updatedClients = state.clientPolicies.map((client) => {
          return client.id === updatedClient.id ? updatedClient : client
        }
        );
        state.clientPolicies = updatedClients;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateClientPolicy.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPolicies.pending, (state) => {
        state.isLoadingPolicies = true;
        state.errorPolicies = null
      })
      .addCase(fetchPolicies.fulfilled, (state, action: PayloadAction<PolicyResponse>) => {
        state.isLoadingPolicies = false;
        state.policies = state.policies.concat({ pageNumber: action.payload.page, pagePolicies: action.payload.policies });
        state.pagePolicies = action.payload.policies;
        state.paginatedOptions.page = action.payload.page;
        state.paginatedOptions.pageSize = action.payload.pageSize;
        state.paginatedOptions.totalPages = action.payload.totalPages;
        state.paginatedOptions.totalCount = action.payload.totalCount;
      })
      .addCase(fetchPolicies.rejected, (state, action: any) => {
        state.isLoadingPolicies = false;
        state.errorPolicies = action.payload;
      })
      .addCase(updatePolicy.pending, (state) => {
        //state.isLoadingPolicies = true;
        state.errorUpdate = null
      })
      .addCase(updatePolicy.fulfilled, (state, action: PayloadAction<Policy>) => {
        //state.isLoading = false;
        state.errorUpdate = null;
        //if (state.paginatedOptions.page === state.paginatedOptions.totalPages) {
        const updatedPolicy = action.payload;
        const updatedPagePolicies = state.pagePolicies.map((policy) => {
          return policy.id === updatedPolicy.id ? updatedPolicy : policy
        });
        const updatedPolicies = state.policies;
        updatedPolicies[state.paginatedOptions.page].pagePolicies = updatedPagePolicies;
        state.policies = updatedPolicies;
        state.pagePolicies = updatedPagePolicies;
        //}
      })
      .addCase(updatePolicy.rejected, (state, action: any) => {
        //state.isLoading = false;
        state.errorUpdate = action.payload;
      })
      .addCase(pastPage.pending, (state) => {
        state.isLoadingPolicies = true;
        state.errorPolicies = null
      })
      .addCase(pastPage.fulfilled, (state) => {
        state.isLoadingPolicies = false;
        state.errorPolicies = null
        const result = state.policies.find(x => x.pageNumber === state.paginatedOptions.page - 1);
        if (result) {
          state.pagePolicies = result.pagePolicies;
          state.paginatedOptions.page = state.paginatedOptions.page - 1;
        }
      });
  },
});

export default policiesSlice.reducer;