import { CallStatus } from "../../enums/CallStatus";
import { CallDirection } from "../../enums/TwilioCalls";

const failedColor = '#E11717';
export const CallStatusIcon = (props: { type: CallDirection, status: CallStatus }) => {
    return props.type === CallDirection.INBOUND
        ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.25 8.12496C16.25 8.29072 16.1842 8.4497 16.0669 8.56691C15.9497 8.68412 15.7908 8.74996 15.625 8.74996H11.875C11.7092 8.74996 11.5503 8.68412 11.4331 8.56691C11.3158 8.4497 11.25 8.29072 11.25 8.12496V4.37497C11.25 4.2092 11.3158 4.05023 11.4331 3.93302C11.5503 3.81581 11.7092 3.74997 11.875 3.74997C12.0408 3.74997 12.1997 3.81581 12.3169 3.93302C12.4342 4.05023 12.5 4.2092 12.5 4.37497V6.61663L16.4333 2.6833C16.4906 2.62189 16.5596 2.57264 16.6362 2.53848C16.7129 2.50432 16.7956 2.48595 16.8796 2.48447C16.9635 2.48299 17.0468 2.49843 17.1247 2.52986C17.2025 2.5613 17.2732 2.60808 17.3325 2.66743C17.3919 2.72678 17.4387 2.79748 17.4701 2.8753C17.5015 2.95312 17.517 3.03648 17.5155 3.1204C17.514 3.20432 17.4956 3.28708 17.4615 3.36375C17.4273 3.44041 17.3781 3.50941 17.3167 3.56663L13.3833 7.49996H15.625C15.7908 7.49996 15.9497 7.56581 16.0669 7.68302C16.1842 7.80023 16.25 7.9592 16.25 8.12496Z" fill={props.status !== CallStatus.COMPLETED ? failedColor : "#2EAC4A"} />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.25 3.75C1.25 3.08696 1.51339 2.45107 1.98223 1.98223C2.45107 1.51339 3.08696 1.25 3.75 1.25H4.89333C5.61 1.25 6.235 1.73833 6.40917 2.43333L7.33 6.11917C7.40462 6.4175 7.38955 6.73122 7.28667 7.02102C7.18379 7.31082 6.99769 7.56383 6.75167 7.74833L5.67417 8.55667C5.56167 8.64083 5.5375 8.76417 5.56917 8.85C6.03948 10.129 6.78216 11.2906 7.74579 12.2542C8.70941 13.2178 9.87096 13.9605 11.15 14.4308C11.2358 14.4625 11.3583 14.4383 11.4433 14.3258L12.2517 13.2483C12.4362 13.0023 12.6892 12.8162 12.979 12.7133C13.2688 12.6105 13.5825 12.5954 13.8808 12.67L17.5667 13.5908C18.2617 13.765 18.75 14.39 18.75 15.1075V16.25C18.75 16.913 18.4866 17.5489 18.0178 18.0178C17.5489 18.4866 16.913 18.75 16.25 18.75H14.375C7.12667 18.75 1.25 12.8733 1.25 5.625V3.75Z" fill={props.status !== CallStatus.COMPLETED ? failedColor : "#2EAC4A"} />
        </svg>
        : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 3.125C12.5 2.95924 12.5658 2.80027 12.683 2.68306C12.8002 2.56585 12.9592 2.5 13.125 2.5H16.875C17.0407 2.5 17.1997 2.56585 17.3169 2.68306C17.4341 2.80027 17.5 2.95924 17.5 3.125V6.875C17.5 7.04076 17.4341 7.19973 17.3169 7.31694C17.1997 7.43415 17.0407 7.5 16.875 7.5C16.7092 7.5 16.5502 7.43415 16.433 7.31694C16.3158 7.19973 16.25 7.04076 16.25 6.875V4.63333L12.3166 8.56667C12.2594 8.62807 12.1904 8.67732 12.1137 8.71148C12.0371 8.74564 11.9543 8.76401 11.8704 8.76549C11.7865 8.76697 11.7031 8.75154 11.6253 8.7201C11.5475 8.68867 11.4768 8.64188 11.4174 8.58253C11.3581 8.52318 11.3113 8.45249 11.2799 8.37466C11.2484 8.29684 11.233 8.21348 11.2345 8.12956C11.236 8.04565 11.2543 7.96288 11.2885 7.88622C11.3226 7.80955 11.3719 7.74055 11.4333 7.68333L15.3666 3.75H13.125C12.9592 3.75 12.8002 3.68415 12.683 3.56694C12.5658 3.44973 12.5 3.29076 12.5 3.125Z" fill={props.status !== CallStatus.COMPLETED ? failedColor : "#525252"} />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.25 3.75C1.25 3.08696 1.51339 2.45107 1.98223 1.98223C2.45107 1.51339 3.08696 1.25 3.75 1.25H4.89333C5.61 1.25 6.235 1.73833 6.40917 2.43333L7.33 6.11917C7.40462 6.4175 7.38955 6.73122 7.28667 7.02102C7.18379 7.31082 6.99769 7.56383 6.75167 7.74833L5.67417 8.55667C5.56167 8.64083 5.5375 8.76417 5.56917 8.85C6.03948 10.129 6.78216 11.2906 7.74579 12.2542C8.70941 13.2178 9.87096 13.9605 11.15 14.4308C11.2358 14.4625 11.3583 14.4383 11.4433 14.3258L12.2517 13.2483C12.4362 13.0023 12.6892 12.8162 12.979 12.7133C13.2688 12.6105 13.5825 12.5954 13.8808 12.67L17.5667 13.5908C18.2617 13.765 18.75 14.39 18.75 15.1075V16.25C18.75 16.913 18.4866 17.5489 18.0178 18.0178C17.5489 18.4866 16.913 18.75 16.25 18.75H14.375C7.12667 18.75 1.25 12.8733 1.25 5.625V3.75Z" fill={props.status !== CallStatus.COMPLETED ? failedColor : "#525252"} />
        </svg>

};