import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Lead } from "../../models/Lead";
import { createAutomationLead, createLead, fetchLeads, startLeadAutomation, updateLead, uploadLeads } from "./actions";

interface LeadState {
  currentLead? : Lead,
  leads: Lead[],
  isLoading: boolean,
  error: string | null
}

const initialState: LeadState = {
  leads: [],
  isLoading: false,
  error: null
}

// slice
const leadsSlice = createSlice({
  name: "leads",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeads.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(fetchLeads.fulfilled, (state, action: PayloadAction<Lead[]>) => {
        state.isLoading = false;
        state.leads = action.payload;
      })
      .addCase(fetchLeads.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createAutomationLead.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createAutomationLead.fulfilled, (state, action: PayloadAction<Lead>) => {
        state.isLoading = false;
        state.leads = state.leads.concat(action.payload);
      })
      .addCase(createAutomationLead.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createLead.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(createLead.fulfilled, (state, action: PayloadAction<Lead>) => {
        state.isLoading = false;
        state.leads = state.leads.concat(action.payload);
      })
      .addCase(createLead.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateLead.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(updateLead.fulfilled, (state, action: PayloadAction<Lead>) => {
        const updatedLead = action.payload;
        const updatedLeads = state.leads.map((lead) =>
          lead.id === updatedLead.id ? updatedLead : lead
        );
        state.leads = updatedLeads;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateLead.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(uploadLeads.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(uploadLeads.fulfilled, (state, action: PayloadAction<Lead[]>) => {
        state.isLoading = false;
        state.leads = state.leads.concat(action.payload);
      })
      .addCase(uploadLeads.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(startLeadAutomation.pending, (state) => {
        state.isLoading = true;
        state.error = null
      })
      .addCase(startLeadAutomation.fulfilled, (state, action: PayloadAction<any>) => {
        const newLeads = state.leads
        newLeads.forEach((lead) =>{
            if(lead.id === action.payload.lead_id) {
              lead.chat_sid = action.payload.lead_chat_sid
              lead.status_id = action.payload.lead_status
            }
          }
        );

        state.isLoading = false;
        state.leads = newLeads;
      })
      .addCase(startLeadAutomation.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default leadsSlice.reducer;